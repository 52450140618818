import { toast } from 'react-toastify';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import UsersService from '../services/UsersService';

// eslint-disable-next-line consistent-return
export const onGenerateUserName = async ({ message }) => {
  if (!message.data.first_name || !message.data.last_name) {
    toast.error('Please enter a first and last name to generate a username.');
  } else {
    const firstName = message.data.first_name;
    const lastName = message.data.last_name;
    const username = await UsersService.generateUniqueUsername(firstName, lastName);

    return { username };
  }
};

export const onRemoveTeam = ({ message, username }) => {
  let onConfirm;
  const team = message.value;

  return toast.promise(
    new Promise((resolve) => {
      onConfirm = resolve;
    }),
    {
      pending: {
        render() {
          return (
            <ConfirmToast
              config={{
                title: 'Remove from Team',
                message: `Are you sure you want to remove the user "${username}" from the "${team.name}" team?`,
                cancelButton: 'Cancel',
                confirmButton: 'Remove',
              }}
              confirm={() => {
                let teams = [...message.data.teams];
                teams = teams.filter((t) => t.id !== team.id);

                onConfirm({ teams });
              }}
              cancel={() => {
                onConfirm(message.data.teams);
              }}
            />
          );
        },
      },
    },
    { isLoading: false, toastId: message.type },
  );
};

export const onAddTean = ({ message }) => {
  const teams = [...message.data.teams];
  const team = message.value;
  teams.push(team);

  return { teams };
};

export const onAddOrganization = ({ message }) => {
  const orgs = [...message.data.organizations];
  const newOrg = { ...message.value };

  const found = orgs.find((o) => o.relation === 'primary');
  newOrg.relation = found ? 'secondary' : 'primary';
  orgs.push(newOrg);

  return { organizations: orgs };
};

export const onRemoveOrganization = ({ message, username }) => {
  let onConfirm;
  const org = message.value;

  return toast.promise(
    new Promise((resolve) => {
      onConfirm = resolve;
    }),
    {
      pending: {
        render() {
          return (
            <ConfirmToast
              config={UsersService.removeUserOrganizationToastConfig(
                username,
                org.name,
              )}
              confirm={() => {
                let orgs = [...message.data.organizations];
                orgs = orgs.filter((o) => o.id !== org.id);

                onConfirm({ organizations: orgs });
              }}
              cancel={() => {
                onConfirm(message.data.organizations);
              }}
            />
          );
        },
      },
    },
    { isLoading: false, toastId: message.type },
  );
};

export const onMarkPrimaryOrganization = ({ message }) => {
  const orgs = [...message.data.organizations];
  const newPrimaryOrg = { ...message.value };

  orgs.map((org) => {
    if (org.relation === 'primary') {
      org.relation = 'secondary';
    }
    if (org.id === newPrimaryOrg.id) {
      org.relation = 'primary';
    }

    return org;
  });

  return { organizations: orgs };
};
