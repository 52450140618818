import FetchService from 'services/FetchService';

export default class HubRelationsService {
  static getHubRelations(clientId) {
    return FetchService.fetchData('GET', `hub-relations?client_id=${clientId}`);
  }

  static validateClientKey(clientId, body) {
    return FetchService.fetchData('POST', `hub-relations/validate?client_id=${clientId}`, body);
  }

  static createClientRelation(clientId, body) {
    return FetchService.fetchData('POST', `hub-relations?client_id=${clientId}`, body);
  }
}
