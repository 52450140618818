import React, { useEffect, useState } from 'react';
import { InputGroup, Button, Form } from 'react-bootstrap';
import FormValidation from '../../utils/FormValidation';

export default function TextInput(props) {
  const {
    question,
    value,
    callbackFunction,
    onBtnClick,
  } = props;

  const [isValid, setIsValid] = useState(true);
  const [selectedValue, setSelectedValue] = useState([]);

  const handleValidation = (inputValue) => {
    if (!question.validation) {
      return true;
    }

    const validationResult = FormValidation.validateInput(
      inputValue,
      question.validation,
      question.required,
    );

    setIsValid(validationResult);

    return validationResult;
  };

  const handleChange = (e) => {
    if (question.validation) {
      const valid = handleValidation(e.target.value);

      callbackFunction({
        target: {
          name: question.field,
          value: e.target.value,
          type: valid ? 'VALID' : 'NOT_VALID',
        },
      });
    } else {
      callbackFunction(e);
    }
  };

  useEffect(() => {
    const newValue = question.disabled && question.formatter ? question.formatter(value) : value;
    setSelectedValue(newValue);
  }, [value]);

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      <InputGroup>
        <Form.Control
          placeholder={question.placeholder || question.label}
          required={question.required}
          name={question.field}
          onChange={handleChange}
          disabled={question.disabled}
          value={selectedValue}
          maxLength={question.maxLength}
          pattern={question.pattern}
          style={{ zIndex: '0' }}
          isInvalid={!isValid}
        />

        {question.button && (
          <Button id={question.button.id} onClick={onBtnClick}>
            {question.button.text}
          </Button>
        )}
      </InputGroup>
      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
