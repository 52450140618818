import {
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';

function SearchBar({
  placeholder,
  onChange,
}) {
  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <Row>
        <Col md="12">
          <InputGroup size="sm" className="mb-3 search-bar-textbox" hasValidation>
            <InputGroup.Text id="inputGroup-sizing-sm">
              <Search />
            </InputGroup.Text>
            <FormControl
              required
              size="sm"
              type="search"
              placeholder={placeholder}
              onChange={onChange}
            />
            <Form.Control.Feedback type="invalid">
              Invalid characters.
            </Form.Control.Feedback>
          </InputGroup>
        </Col>
      </Row>
    </Form>
  );
}

export default SearchBar;
