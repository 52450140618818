import {
  React,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useBlocker } from 'hooks/useBlocker';
import { AgGridReact } from 'ag-grid-react';
import { useParams } from 'react-router';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SearchBar from 'components/Search/SearchBar';
import Subheader from 'components/Subheader';
import JulotaFormNew from 'components/Forms/JulotaFormNew';
import SidePanel from 'components/Table/SidePanel';
import Tooltip from 'components/Table/Tooltip';

import ConfirmToast from 'modules/core/components/ConfirmToast';
import GenericUtils from 'utils/GenericUtils';
import TeamService from '../services/TeamService';

export default function Teams() {
  const { clientId } = useParams();
  const [teams, setTeams] = useState('');
  const [questions, setQuestions] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [panel, setPanel] = useState();
  const [loading, setLoading] = useState(false);

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Name / Description',
      tooltipValueGetter: (p) => ({
        title: p.data.name,
        subtitle: p.data.description,
      }),
      tooltipComponent: Tooltip,
      cellRenderer: ({ data: { name, description } }) => GenericUtils
        .getCellRenderer({ name, description }),
      sortable: true,
    },
    { headerName: 'Description', field: 'description', tooltipField: 'description' },
    {
      headerName: 'Status',
      field: 'status',
      tooltipField: 'status',
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      headerClass: 'text-center',
    },
    {
      headerName: 'Created By', field: 'createdby', tooltipField: 'createdby', width: 100,
    },
    {
      headerName: 'Create Date',
      field: 'createdt',
      tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
      cellRenderer: (parameters) => GenericUtils.formatDateShort(parameters.value),
      width: 150,
    },
  ]);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const checkUnsavedChanges = (onConfirm) => {
    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const blocker = useCallback((tx) => {
    checkUnsavedChanges(() => {
      tx.retry();
      setPanel(null);
      setFormDirty(false);
    });
  }, [formDirty]);

  useBlocker(blocker, formDirty);

  const onRowClicked = useCallback(async (params) => {
    checkUnsavedChanges(async () => {
      const { id } = params.api.getSelectedRows()[0];
      const team = await TeamService.getTeam(clientId, id);

      setPanel({
        key: team.key,
        title: team.name,
        subtitle: team.key,
        questions,
        data: {
          ...team,
          users: team.users || [],
        },
      });
      setFormDirty(false);
    });
  }, [formDirty, questions]);

  const createTeam = async () => {
    setPanel({
      key: 'new-team',
      title: 'Add Team',
      questions,
      data: {
        status: 'active',
        users: [],
      },
      submitText: 'Add Team',
    });
  };

  const closeSidePanel = () => {
    checkUnsavedChanges(() => {
      setPanel(null);
      setFormDirty(false);
    });
  };

  const setupComponent = async () => {
    const qs = await TeamService.getQuestions(clientId);
    setQuestions(qs);

    const res = await TeamService.getAll(clientId);
    setTeams(res);
    setGridData(res);
  };

  useEffect(() => {
    setupComponent();
  }, []);

  const onFormChange = async (params) => {
    setFormDirty(params.isDirty);
    setPanel({
      ...panel,
      questions,
    });
  };

  const onFormSubmit = async (params) => {
    try {
      const { data } = params;
      setLoading(true);

      const invalidName = teams
        .filter((t) => t.id !== data.id?.toString())
        .map((t) => t.name)
        .filter((tn) => tn.trim().toLowerCase() === data.name.trim().toLowerCase());

      if (invalidName.length > 0) {
        toast.error('Existing Team with that name. Name must be unique');
      } else {
        await TeamService.upsertTeam(clientId, data.id, {
          ...data,
          users: data.users?.map((u) => u.key),
        });
        toast.success('Changes Saved!');
        setFormDirty(false);
        setupComponent();
      }
    } catch (error) {
      toast.error(error);
      setFormDirty(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setGridData(searchText.length > 0 ? GenericUtils.search(teams, searchText) : teams);
  }, [searchText]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Teams</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar placeholder="Search Teams..." onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-3 text-align-right">
          <Button variant="link" onClick={createTeam}>
            Create Team
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>

      {
        panel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 650 }}
          >
            <SidePanel.Header
              title={panel.title}
              subtitle={panel.subtitle}
            />
            <SidePanel.Content className="no-tabs">
              <JulotaFormNew
                questions={panel.questions}
                data={panel.data}
                isDirty={formDirty}
                isDisabled={panel.disabled}
                submitText={panel.submitText}
                onFormChange={onFormChange}
                onFormSubmit={onFormSubmit}
              />
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
