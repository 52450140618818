import { Outlet } from 'react-router-dom';
import '../styles/Login.scss';

export default function Logout() {
  return (
    <div className="login-page">
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-6 left-panel">
          <img className="logo" src="/julota-logo.png" alt="julota-logo" />
          <div className="app-name">
            <div>Config Admin v1.0.0</div>
            <div>©2015 Julota</div>
          </div>
        </div>
        <div className="col-md-6 right-panel">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
