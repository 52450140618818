import {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Form } from 'react-bootstrap';
import SearchBar from 'components/Search/SearchBar';
import GenericUtils from 'utils/GenericUtils';
import { toast } from 'react-toastify';
import Subheader from 'components/Subheader';
import UsageSidePanel from '../components/UsageSidePanel';
import UsagesHelper from '../helpers/UsagesHelper';
import '../styles/Usage.scss';
import UsageLoading from '../components/UsageLoading';
import ClientUsageService from '../services/ClientUsageService';

function ClientUsage() {
  const gridRef = useRef();
  const [search, setSearch] = useState('');
  const [usages, setUsages] = useState();
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [rowSelect, setRowSelect] = useState(null);
  const [monthSelected, setMonthSelected] = useState(4);
  const [sidepanelLoading, setSidepanelLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  let gridApi = null;

  const onGridReady = (params) => {
    setLoading(false);
    gridApi = params.api;
    params.api.autoSizeAllColumns?.();
    window.onresize = () => {
      params.api.autoSizeAllColumns?.();
    };
  };

  const getRowStyle = useCallback((params) => {
    if (params.node.rowPinned) {
      return { fontWeight: 'bold' };
    }

    return {};
  }, []);

  const pinnedBottomRowData = () => gridData && UsagesHelper.setButtomPinnedRowData(gridData);

  const onSelectionChanged = useCallback(() => {
    setSidepanelLoading(true);
    const selectedRows = gridRef.current.api.getSelectedRows();
    setRowSelect(selectedRows);
  }, []);

  const closeSidePanel = () => {
    setRowSelect(null);
  };

  const defaultColDef = useMemo(() => ({
    width: 170,
    sortable: true,
    suppressMovable: true,
    resizable: true,
  }), []);

  useEffect(() => {
    if (usages?.length > 0) {
      setColumnDefs(UsagesHelper.mapUsagesData(usages));
      setGridData(UsagesHelper.reformatUsageData(usages));
    }
  }, [usages]);

  const fetchUsages = async (months) => {
    try {
      setLoading(true);
      const u = await ClientUsageService.getUsages(months);
      setUsages(u);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsages(monthSelected).catch(toast.error);
  }, [monthSelected]);

  useEffect(() => {
    if (search.length > 0 && usages) {
      setGridData(
        GenericUtils.search(UsagesHelper.reformatUsageData(usages), search),
      );
    } else if (usages) {
      setGridData(UsagesHelper.reformatUsageData(usages));
    }
  }, [search]);

  return (
    <div className="container-padding-40 usagesContent">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Client Usage</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>

        <div className="col-6">
          <SearchBar
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="col-3">
          <div className="usagesContent--dropdown">
            <Form.Label className="dropdownMonthsLabel" column="sm">Running Average</Form.Label>
            <Form.Select defaultValue={monthSelected} className="dropdownMonthsSelect" onChange={(e) => setMonthSelected(e.target.value)}>
              {
                UsagesHelper.setMonthsUsage.map(
                  (opts) => <option value={opts.value} key={opts.id}>{opts.text}</option>,
                )
              }
            </Form.Select>
          </div>
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        {(usages && !loading)
          ? (
            <AgGridReact
              ref={gridRef}
              rowData={gridData}
              columnDefs={columnDefs}
              tooltipShowDelay={1500}
              rowSelection="single"
              suppressContextMenu
              suppressHeaderContextMenu
              rowMultiSelectWithClick
              suppressScrollOnNewData="true"
              animateRows
              rowHeight="65"
              columnHoverHighlight={false}
              defaultColDef={defaultColDef}
              pinnedBottomRowData={pinnedBottomRowData()}
              onGridReady={onGridReady}
              getRowStyle={getRowStyle}
              onSelectionChanged={onSelectionChanged}
            />
          )
          : (
            <UsageLoading />
          )}
      </div>

      {
        rowSelect && rowSelect.length === 1
        && (
          <UsageSidePanel
            id={rowSelect[0].id}
            name={rowSelect[0].name}
            closeSidePanel={closeSidePanel}
            loading={sidepanelLoading || loading}
            setLoading={setSidepanelLoading}
          />
        )
      }
    </div>
  );
}

export default ClientUsage;
