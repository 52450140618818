import { Card, Placeholder } from 'react-bootstrap';

function UsageLoading() {
  return (
    <Placeholder as={Card.Text} animation="glow">
      <Placeholder xs={12} />
      <Placeholder xs={6} />
      <Placeholder xs={8} />
      <Placeholder xs={6} />
      <Placeholder xs={12} />
    </Placeholder>
  );
}

export default UsageLoading;
