import { useState } from 'react';
import { Form } from 'react-bootstrap';
import cronstrue from 'cronstrue';
import ErrorList from './ErrorList';

export default function CronInput(props) {
  const { question, value, callbackFunction } = props;
  const [errors, setErrors] = useState([]);

  const onChange = (e) => {
    try {
      cronstrue.toString(e.target.value);
      setErrors([]);

      callbackFunction({
        target: {
          name: question.field,
          value: e.target.value,
          type: 'VALID',
        },
      });
    } catch (error) {
      setErrors([error]);

      callbackFunction({
        target: {
          name: question.field,
          value: e.target.value,
          type: 'NOT_VALID',
        },
      });
    }
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>

      {errors.length > 0 && <ErrorList errors={errors} />}

      <Form.Control
        placeholder={question.placeholder || question.label}
        required={question.required}
        name={question.field}
        onChange={onChange}
        disabled={question.disabled}
        value={value}
        isInvalid={errors.length > 0}
      />

      {errors.length === 0 && value && (
        <Form.Text as="p" style={{ marginTop: 8 }}>
          {cronstrue.toString(value)}
        </Form.Text>
      )}

    </Form.Group>
  );
}
