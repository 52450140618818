import { useState } from 'react';
import ReactDatePicker from 'react-date-picker';

export default function DatePicker(props) {
  const [value, onChange] = useState(null);

  return (
    <ReactDatePicker format="MM/dd/yyyy" value={value} onChange={onChange} />
  );
}
