import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

export default function DropDown(props) {
  const {
    question,
    value,
    className,
    callbackFunction,
  } = props;

  const [selectedValue, setSelectedValue] = useState([]);

  const onChange = (params) => {
    const formValue = params?.value;

    callbackFunction({
      target: {
        name: question.field,
        value: formValue,
      },
    });
  };

  useEffect(() => {
    const newValue = question.options.find((e) => e.value?.toString() === value?.toString());
    setSelectedValue(newValue);
  }, [value]);

  return (
    <Form.Group className={className || 'mb-3'}>
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      <Select
        className="basic-single"
        isClearable={question.isClearable}
        options={question.options}
        name={question.field}
        value={selectedValue}
        onChange={onChange}
        required={question.required}
        isDisabled={question.disabled}
        placeholder={question.placeholder || question.label}
        classNames={{
          control: (state) => (question.required && !value ? 'required' : ''),
        }}
      />

      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
