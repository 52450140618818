import { Form } from 'react-bootstrap';
import hash from 'object-hash';

export default function DropDown(props) {
  const { question } = props;

  return (
    <Form.Select size="sm" style={{ width: 300 }}>
      {question.options && question.options.map(
        (option) => <option value={option.value} key={hash(option)}>{option.label}</option>,
      )}
    </Form.Select>
  );
}
