import {
  useState,
  useEffect,
} from 'react';
import { Form } from 'react-bootstrap';

export default function Terminology(props) {
  const {
    question,
    value: defaultValue,
    callbackFunction,
  } = props;
  const [selectedValue, setSelectedValue] = useState({
    checkValue: defaultValue && defaultValue.value,
    textValue: defaultValue ? defaultValue.static || defaultValue.value : null,
  });

  const onChange = (type, e) => {
    const { value, checked } = e.target;

    setSelectedValue({
      ...selectedValue,
      ...(type === 'checkbox' && { checkValue: checked }),
      ...(type === 'text' && { textValue: value }),
    });
  };

  useEffect(() => {
    callbackFunction({
      target: {
        name: question.field,
        value: {
          [selectedValue.checkValue ? 'value' : 'static']: selectedValue.textValue,
        },
      },
    });
  }, [selectedValue]);

  return (
    <Form.Group className="mb-3">
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      <Form.Group className="float-right">
        <Form.Check type="checkbox" label="Use Plurals" checked={selectedValue.checkValue} onChange={(e) => onChange('checkbox', e)} />
      </Form.Group>
      <Form.Control
        placeholder={question.placeholder || question.label}
        required={question.required}
        name={question.field}
        onChange={(e) => onChange('text', e)}
        disabled={question.disabled}
        value={selectedValue.textValue}
      />
      <Form.Control.Feedback type="invalid">
        Missing Required Field
      </Form.Control.Feedback>
    </Form.Group>
  );
}
