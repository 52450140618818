import {
  Link,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import classNames from 'classnames';

export default function CustomLink({ children, to, ...props }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  const classes = classNames({
    active: match,
  });

  return (
    <Link className={classes} to={to} {...props}>
      {children}
    </Link>
  );
}
