import {
  React,
  useState,
  useEffect,
} from 'react';
import GenericUtils from 'utils/GenericUtils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { Button } from 'react-bootstrap';
import SidePanel from 'components/Table/SidePanel';
import Subheader from 'components/Subheader';
import SearchBar from 'components/Search/SearchBar';
import JulotaFormNew from 'components/Forms/JulotaFormNew';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import { useSelector } from 'react-redux';
import { selectSelectedClientHubType } from 'redux/selectors';
import HubRelationsService from '../services/HubRelationsService';

export default function HubRelations() {
  const { clientId } = useParams();
  const hubType = useSelector(selectSelectedClientHubType);

  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [panel, setPanel] = useState(null);
  const [formDirty, setFormDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Client Name',
      sortable: true,
      tooltipField: 'name',
    },
    {
      field: 'key',
      headerName: 'Client Key',
      sortable: true,
      tooltipField: 'key',
    },
    {
      field: 'hub_type',
      headerName: 'Client Type',
      sortable: true,
      tooltipField: 'hub_type',
      cellRenderer: ({ value }) => value.split('-').map((v) => GenericUtils.capitalizeFirstLetter(v)).join(' '),
    },
    {
      headerName: 'Relationship Status',
      field: 'status',
      cellRenderer: ({ value }) => GenericUtils.capitalizeFirstLetter(value),
      cellStyle: (params) => GenericUtils.statusCellColor(params.value === 'active'),
      width: 150,
    },
  ]);

  const checkUnsavedChanges = (onConfirm) => {
    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const fetchHubRelations = async () => {
    const mpi = await HubRelationsService.getHubRelations(clientId);
    setGridData(mpi);
  };

  const onSearchChange = (e) => {
    const { target: { value } } = e;
    setSearchText(value);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const closeSidePanel = () => {
    checkUnsavedChanges(() => {
      setPanel(null);
      setFormDirty(false);
    });
  };

  const onAddHubRelation = () => {
    setPanel({
      title: 'Add Hub Relation',
      questions: [
        {
          label: 'Client Key',
          field: 'clientKey',
          type: 'TEXT',
          required: true,
        },
      ],
      data: {
        clientKey: '',
      },
      submitText: 'Add Relation',
    });
  };

  const onFormChange = async (params) => {
    setFormDirty(params.isDirty);
  };

  const onAddRelations = async (newClientId) => {
    try {
      setLoading(true);
      await HubRelationsService.createClientRelation(clientId, { newClientId });
      toast.success('Relation created succesfully');
      fetchHubRelations();
      setPanel(null);
      setFormDirty(false);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFormSubmit = async (params) => {
    try {
      setLoading(true);
      const response = await HubRelationsService.validateClientKey(clientId, params.data);
      if (response.client_id) {
        toast(
          <ConfirmToast
            config={GenericUtils.confirmAddRelation}
            confirm={() => onAddRelations(response.client_id)}
          />,
          GenericUtils.confirmToastOptions,
        );
      }
    } catch (error) {
      toast.error('The Client Key provided wasn’t valid');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHubRelations();
  }, []);

  return (
    <div className="container-padding-40">

      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Hub Relations</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Hub Relations..."
            onChange={onSearchChange}
          />
        </div>
        {hubType === 'support-hub' && (
          <div className="col-md-3 text-align-right">
            <Button variant="link" onClick={onAddHubRelation}>
              Add Hub Relation
            </Button>
          </div>
        )}
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={GenericUtils.search(gridData, searchText)}
          getRowStyle={GenericUtils.preserveRowBackground}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onGridReady={onGridReady}
        />
      </div>
      {
        panel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 700 }}
          >
            <SidePanel.Header
              title={panel.title}
              subtitle={panel.subtitle}
            />
            <SidePanel.Content className="no-tabs">
              <JulotaFormNew
                questions={panel.questions}
                data={panel.data}
                isDirty={formDirty}
                viewMode={panel.viewMode}
                isDisabled={panel.disabled}
                submitText={panel.submitText}
                onFormChange={onFormChange}
                onFormSubmit={onFormSubmit}
              />
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
