import { ExclamationTriangle } from 'react-bootstrap-icons';

export default function MessageIcon(props) {
  const { icon } = props;
  switch (icon) {
    case 'exclamation-triangle':
      return <ExclamationTriangle size="2.5em" />;
    default:
      return null;
  }
}
