import FetchService from 'services/FetchService';

export default class MpiService {
  static getMpi(clientId) {
    return FetchService.fetchData('GET', `mpi?client_id=${clientId}`);
  }

  static getClientIdOptions(clientId) {
    return FetchService.fetchData('GET', `mpi/options?client_id=${clientId}`);
  }

  static createMpi(supportHubClientId, body) {
    return FetchService.fetchData('POST', `mpi?support_hub_client_id=${supportHubClientId}`, body);
  }
}
