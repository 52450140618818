import { useEffect, useRef, useState } from 'react';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  ButtonGroup,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { requestTwoFactor } from 'redux/actions';
import { selectTwoFactor, selectMultiFactorSMS, selectMultiFactorEmail } from 'redux/selectors';
import { ShieldLock } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormValidation from '../../../utils/FormValidation';
import AuthService from '../services/AuthService';

function TwoFactorAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const twoFactor = useSelector(selectTwoFactor);
  const multiFactorSMS = useSelector(selectMultiFactorSMS);
  const multiFactorEmail = useSelector(selectMultiFactorEmail);
  const [loading, setLoading] = useState(false);
  const tokenInputRef = useRef(null);
  const [otpIsValid, setOtpIsValid] = useState(false);
  const [vtoken, setVtoken] = useState('');
  const [loadAuthentication, setLoadAuthentication] = useState(false);

  const validate = () => setOtpIsValid(FormValidation.onlyNumbers(vtoken));

  useEffect(() => {
    validate();
  }, [vtoken]);

  useEffect(() => {
    if (twoFactor) {
      tokenInputRef.current.focus();
    }
  }, [twoFactor]);

  const handleVerify = async (e, type) => {
    e.preventDefault();
    setLoading(true);
    await AuthService.requestToken({ source: e.target.value });
    dispatch(requestTwoFactor());
    toast.info(`Token sent, please check your ${type === 'email' ? 'inbox' : 'messages'}`);
  };

  const handleSetVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoadAuthentication(true);
    try {
      await AuthService.activateSession({ authentication_token: vtoken });
      navigate('/');
    } catch (error) {
      toast.error('Something went wrong, please try again');
      setVtoken('');
    }
  };

  const validateFormToken = () => vtoken.length > 3;

  return (
    <Form onSubmit={handleSetVerify}>
      <div className="title">Two-Factor Authentication</div>
      <p>Please select an option to request a token and enter the code below</p>
      <ButtonGroup size="sm" className="">
        <Button
          disabled={!multiFactorSMS}
          variant={FormValidation.styleSendRequestValidateCode(
            multiFactorSMS,
            twoFactor,
          )}
          value="sms"
          onClick={(e) => handleVerify(e, 'sms')}
          id="sms-button"
        >
          SMS
        </Button>
        <Button
          disabled={!multiFactorEmail}
          variant={FormValidation.styleSendRequestValidateCode(
            multiFactorEmail,
            twoFactor,
          )}
          value="email"
          onClick={(e) => handleVerify(e, 'email')}
          id="email-button"
        >
          EMAIL
        </Button>
      </ButtonGroup>
      {loading && !loadAuthentication && (
        <Spinner
          animation="grow"
          variant={twoFactor ? 'success' : 'primary'}
          size="sm"
          style={{ color: 'var(--color-6)' }}
        />
      )}
      <br />
      <br />
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text
          id="inputGroup-sizing-sm"
          style={FormValidation.validateStyleColor(
            vtoken,
            vtoken.length > 0 ? otpIsValid : true,
          )}
        >
          <ShieldLock />
        </InputGroup.Text>
        <FormControl
          autoFocus
          type="code"
          ref={tokenInputRef}
          placeholder={!twoFactor ? '' : 'Token'}
          value={vtoken}
          onChange={(e) => setVtoken(e.target.value)}
          disabled={!twoFactor}
          required
          isInvalid={vtoken.length !== 0 && !otpIsValid}
        />
        <Form.Control.Feedback type="invalid">
          Invalid characters.
        </Form.Control.Feedback>
      </InputGroup>
      <Button
        size="lg"
        disabled={!validateFormToken() || !otpIsValid}
        type="submit"
      >
        Verify
        {loading && loadAuthentication && (
          <Spinner animation="grow" variant="light" size="sm" />
        )}
      </Button>
    </Form>
  );
}

export default TwoFactorAuth;
