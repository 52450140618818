import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedClientVersionNumber } from 'redux/selectors';
import { setClient } from 'redux/actions';
import { useParams, useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import hash from 'object-hash';
import {
  Trash,
  FilePlus,
  Dropbox,
  Plus,
} from 'react-bootstrap-icons';
import { Button, Form, Modal } from 'react-bootstrap';
import JulotaForm from 'components/Forms/JulotaForm';
import SidePanel from 'components/Table/SidePanel';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import GenericUtils from 'utils/GenericUtils';

import ComponentsService from '../services/ComponentsService';
import LayoutService from '../services/LayoutService';

import ComponentPanel from './ComponentPanel';
import '../styles/Profile.scss';
import ClientsService from '../services/ClientsService';

export default function Layout(props) {
  const { clientId } = useParams();
  const { layoutKey } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedClientVersionNumber = useSelector(selectSelectedClientVersionNumber);

  // States
  const [show, setShow] = useState(false);
  const [panel, setPanel] = useState(null);
  const [formDirty, setFormDirty] = useState(false);
  const [entityPage, setEntityPage] = useState({});
  const [versionList, setVersionList] = useState([]);
  const [additionalComponents, setAdditionalComponents] = useState();

  const fetchData = async (versionNumber) => {
    const entityPageRes = await LayoutService.getLayout(clientId, layoutKey, versionNumber);

    setEntityPage({ ...entityPageRes, version_number: versionNumber });

    const componentList = await LayoutService.getComponetsToAdd(
      clientId,
      entityPageRes.entityType,
      entityPageRes.entityKey,
    );
    setAdditionalComponents(componentList);

    const versions = await LayoutService.getVersionList(clientId, entityPageRes.key);
    setVersionList(versions);
  };

  const closeSidePanel = () => {
    const onConfirm = () => {
      setPanel(null);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const getLayoutVersion = async (versionId) => {
    const layoutVersion = await LayoutService.getLayout(clientId, layoutKey, versionId);
    setEntityPage({ ...layoutVersion, version_number: versionId });
  };

  const refetchClientData = async () => {
    const res = await ClientsService.getClient(clientId);
    dispatch(setClient(res.data));
  };

  const saveDraft = async () => {
    try {
      const res = await LayoutService.saveLayoutDraft(
        clientId,
        entityPage.key,
        entityPage.entityType,
        entityPage.entityKey,
        entityPage.layout,
        selectedClientVersionNumber,
      );

      await fetchData(res.version_number);
      await refetchClientData();
    } catch (error) {
      toast.error('Something went wrong. Unable to save draft.');
    }
  };

  const callbackFunction = async (message) => {
    if (message.type === 'FORM_SUBMITTED') {
      if (panel.key === 'layout-component-panel') {
        const element = entityPage.layout.components.find(
          (c) => c.component_key === message.data.component_key,
        );
        element.size = message.data.size;
        element.choose_containers = message.data.choose_containers;
        element.display_containers = !message.data.choose_containers
          ? null : element.display_containers || [];
        element.title_overwrite = message.data.title_overwrite;
        element.readonly = message.data.readonly;
        element.display_rules = message.data.display_rules;

        await saveDraft();
        toast.success('Component Updated!');
      }

      setFormDirty(false);
      setPanel(null);
    } else if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    }
  };

  const editComponent = async (component) => {
    const element = entityPage.layout.components.find(
      (c) => c.component_key === component.layout.componentKey,
    );

    setPanel({
      key: 'layout-component-panel',
      title: element.title_overwrite || component.layout.title,
      subtitle: component.layout.componentKey,
      width: '70%',
      form: {
        id: uuidv4(),
        questions: await LayoutService.getLayoutComponentFormQuestions(clientId, entityPage.key),
        data: {
          component_key: component.layout.componentKey,
          title: component.layout.title,
          size: element.size,
          choose_containers: element.choose_containers !== undefined
            ? element.choose_containers
            : element.display_containers !== undefined,
          display_containers: element.choose_containers !== undefined && element.choose_containers
            ? element.display_containers || []
            : null,
          title_overwrite: element.title_overwrite,
          protected: element.protected,
          readonly: element.readonly,
          display_rules: element.display_rules,
        },
        disabled: entityPage.status !== 'draft',
      },
    });
  };

  const addComponentToLayout = async (message) => {
    if (message.type === 'ON_CHANGE') {
      setShow(false);

      const { component } = message.data;

      const newComponent = await ComponentsService.getComponent(component, clientId, 0);

      entityPage.components.push(newComponent);
      entityPage.layout.components.push({
        id: -1,
        size: 'full',
        readonly: true,
        component_key: component,
      });

      await saveDraft();
      toast.success('Component Added to layout!');

      await fetchData(selectedClientVersionNumber);
    }
  };

  const deleteComponent = (componentKey) => {
    const onConfirm = async () => {
      const newItems = entityPage.layout.components.filter((i) => i.component_key !== componentKey);
      entityPage.layout.components = newItems;
      await saveDraft();
      toast.success('Component Deleted from layout!');
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Remove Component?',
          message: 'This action will remove the component from the layout.',
          cancelButton: 'Cancel',
          confirmButton: 'Delete',
        }}
        confirm={onConfirm}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const swapElements = (array, index1, index2) => {
    // eslint-disable-next-line prefer-destructuring
    array[index1] = array.splice(index2, 1, array[index1])[0];

    return array;
  };

  const move = async (direction, index) => {
    const toIndex = direction === 'Up' ? index - 1 : index + 1;
    swapElements(entityPage.layout.components, index, toIndex);
    await saveDraft();
  };

  const getComponent = (i) => {
    const component = entityPage.components.find((e) => e.layout.componentKey === i.component_key);

    if (i.display_containers && entityPage.status !== 'draft') {
      component.layout.rows = component.layout.rows.filter(
        (t) => i.display_containers.includes(t.id),
      );
    }
    if (i.display_containers) {
      component.layout.rows.forEach((r) => {
        r.status = i.display_containers && i.display_containers.includes(r.id) ? 'SHOWING' : 'HIDDEN';
      });
    }

    return {
      ...component,
      protected: i.protected,
    };
  };

  const createDraft = async () => {
    const onConfirm = async () => {
      await saveDraft();
      toast.success('Draft Created!');
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Create App Version?',
          message: 'This action will create a draft app version.',
          cancelButton: 'Create',
          confirmButton: 'Cancel',
        }}
        cancel={onConfirm}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const discardChanges = async () => {
    const onConfirm = async () => {
      try {
        await LayoutService.discardDraft(entityPage.id);
        toast.success('Draft Discarded.');
        fetchData(selectedClientVersionNumber);
      } catch (error) {
        toast.error('Error discarding draft.');
      }
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Discard Changes?',
          message: 'This action will discard all unsaved changes.',
          cancelButton: 'Cancel',
          confirmButton: 'Discard',
        }}
        confirm={onConfirm}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const showContainer = async (componentKey, container) => {
    const component = entityPage.layout.components.find((c) => c.component_key === componentKey);
    container.status = 'SHOWING';
    component.display_containers.push(container.id);
    await saveDraft();
  };

  const hideContainer = async (componentKey, container) => {
    const component = entityPage.layout.components.find((c) => c.component_key === componentKey);
    container.status = 'HIDDEN';
    const index = component.display_containers.indexOf(container.id);
    if (index > -1) {
      component.display_containers.splice(index, 1);
    }
    await saveDraft();
  };

  const handleCallbackMessage = async (message) => {
    switch (message.action) {
      case 'EDIT_LAYOUT_COMPONENT':
      case 'DETAILS_LAYOUT_COMPONENT':
        editComponent(message.component);
        break;
      case 'DELETE_LAYOUT_COMPONENT':
        deleteComponent(message.component.layout.componentKey);
        break;
      case 'MOVE_UP_LAYOUT_COMPONENT':
        move('Up', message.index);
        break;
      case 'MOVE_DOWN_LAYOUT_COMPONENT':
        move('Down', message.index);
        break;
      case 'SHOW_CONTAINER':
        showContainer(message.component.layout.componentKey, message.container);
        break;
      case 'HIDE_CONTAINER':
        hideContainer(message.component.layout.componentKey, message.container);
        break;
      case 'EDIT_COMPONENT':
        navigate(`/hubs/${clientId}/components/${message.component.layout.componentKey}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchData(selectedClientVersionNumber);
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div style={{ paddingTop: 10 }}>
      <div className="row">
        <div className="col" style={{ color: 'grey', marginTop: 10, fontWeight: 200 }}>
          The layout below is what it is displayed within julota-reach application
        </div>
        <div className="col-md-4 d-flex align-items-baseline">
          <Form.Label className="me-3 label">Version:</Form.Label>
          <Form.Select
            value={entityPage?.version_number}
            onChange={(e) => getLayoutVersion(e.target.value)}
          >
            {
              versionList.map(
                (v) => <option value={v.value} key={hash(v)}>{v.label}</option>,
              )
            }
          </Form.Select>
        </div>
        <div className="col-12" style={{ textAlign: 'right' }}>
          {
            entityPage.status === 'current' && (
              <Button variant="link" size="sm" onClick={() => createDraft()}>
                <FilePlus />
                <span style={{ paddingLeft: 5 }}>Create Draft</span>
              </Button>
            )
          }
          {
            entityPage.status === 'draft' && (
              <Button variant="link" size="sm" onClick={handleShow} disabled={additionalComponents && additionalComponents.length === 0}>
                <Plus />
                <span style={{ paddingLeft: 5 }}>Add Component</span>
              </Button>
            )
          }
          {
            entityPage.status === 'draft' && (
              <Button variant="link" size="sm" onClick={() => discardChanges()}>
                <Trash />
                <span style={{ paddingLeft: 5 }}>Discard Draft</span>
              </Button>
            )
          }
        </div>
      </div>

      <div className="row reach-background">
        {entityPage && entityPage.layout?.components.map((c, index) => (
          <div className={c.size === 'full' ? 'col-md-12' : 'col-md-6'} key={c.id}>
            <ComponentPanel
              component={getComponent(c)}
              level="layout"
              editMode={entityPage.status === 'draft'}
              showMoveUp={index !== 0}
              showMoveDown={index + 1 !== entityPage.layout?.components.length}
              callbackFunction={handleCallbackMessage}
              cIndex={index}
              titleOverwrite={c.title_overwrite}
            />
          </div>
        ))}
        {
          (entityPage.layout === undefined || entityPage.layout?.components.length === 0) && (
            <div className="col-md-12">
              <div className="centered-message">
                <Dropbox size={90} />
                <div className="message">
                  Empty layout!
                </div>
                <div className="message">
                  Unable to find any layout associated with this entity!
                </div>
              </div>
            </div>
          )
        }
      </div>
      {
        panel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            style={{ width: panel.width }}
          >
            <SidePanel.Header title={panel.title} subtitle={panel.subtitle} />
            <SidePanel.Content className="no-tabs">
              <JulotaForm
                inputData={panel.form}
                formDirty={formDirty}
                callbackFunction={(message) => callbackFunction(message)}
              />
            </SidePanel.Content>
          </SidePanel>
        )
      }

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Component</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: 'visible' }}>
          <JulotaForm
            inputData={{
              questions: LayoutService.getAddComponentForm(additionalComponents),
              data: {
                component: null,
              },
            }}
            callbackFunction={(message) => addComponentToLayout(message)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
