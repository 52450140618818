/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';

export default function DatePicker(props) {
  const [value, onChange] = useState(null);

  return (
    <DateTimePicker onChange={onChange} value={value} />
  );
}
