import { useState } from 'react';
import { X } from 'react-bootstrap-icons';
import classNames from 'classnames';
import './SidePanel.scss';

function SidePanel({
  style,
  children,
  close,
  confirmClosePrompt,
  loading,
}) {
  const [hide, setHide] = useState(false);

  function handleCloseClick() {
    if (confirmClosePrompt === true) {
      close();
    } else {
      setHide(true);
      setTimeout(() => {
        close();
      }, 300);
    }
  }

  const sidePanelClasses = classNames('side-panel', {
    hide,
    loading,
  });

  return (
    <div
      className={sidePanelClasses}
      style={style}
    >
      <X size={32} className="close-icon" onClick={handleCloseClick} />
      {children}
    </div>
  );
}

// SUBCOMPONENT: Fixed Header
SidePanel.Header = function Header({
  title,
  subtitle,
  style,
  children,
}) {
  return (
    <div className="side-panel-header" style={style}>
      <div style={{ width: '100%' }}>
        <h4>{title}</h4>
        <small>{subtitle}</small>
        {children}
      </div>
    </div>
  );
};

// SUBCOMPONENT: Scrollable Content
SidePanel.Content = function Content({
  children,
  style,
  className,
}) {
  return (
    <div className={classNames('side-panel-content', className)} style={style}>
      {children}
    </div>
  );
};

export default SidePanel;
