import { Alert } from 'react-bootstrap';

export default function ErrorList(props) {
  const { errors } = props;

  return (
    <Alert
      variant="danger"
      className="option-errors"
    >
      <p className="option-errors-title">Please review the following errors ...</p>
      <ul>
        {errors.map((v) => <li key={v}>{v}</li>)}
      </ul>
    </Alert>
  );
}
