import {
  React,
  useState,
  useEffect,
} from 'react';
import GenericUtils from 'utils/GenericUtils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { Button } from 'react-bootstrap';
import Tooltip from 'components/Table/Tooltip';
import SidePanel from 'components/Table/SidePanel';
import Subheader from 'components/Subheader';
import SearchBar from 'components/Search/SearchBar';
import JulotaFormNew from 'components/Forms/JulotaFormNew';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import { useSelector } from 'react-redux';
import { selectSelectedClientHubType } from 'redux/selectors';
import MpiService from '../services/MpiService';

const sharingStrategyOptions = [
  { label: 'Support Hub and Client Hub share information bidirectionally', value: 'support<->client' },
  { label: 'Support Hub can access Client Hub data but Client Hub cannot access Support Hub data', value: 'support->client' },
  { label: 'Client Hub can access Support Hub data but Support Hub cannot access Client Hub data', value: 'support<-client' },
];

const getSharingStrategyLabel = (value) => (
  sharingStrategyOptions.find((el) => el.value === value)?.label ?? ''
);

export default function Mpi() {
  const { clientId } = useParams();
  const hubType = useSelector(selectSelectedClientHubType);

  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [panel, setPanel] = useState(null);
  const [formDirty, setFormDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [columnDefs] = useState([
    {
      field: 'support_hub',
      headerName: 'Support Hub',
      sortable: true,
      cellRenderer: ({ data }) => (GenericUtils.getCellRenderer({
        name: data.support_hub_name, description: data.support_hub_key,
      })),
      tooltipValueGetter: (p) => ({
        title: p.data.support_hub_name,
        subtitle: p.data.support_hub_key,
      }),
      tooltipComponent: Tooltip,
    },
    {
      field: 'standard_hub',
      headerName: 'Standard Hub',
      sortable: true,
      cellRenderer: ({ data }) => (GenericUtils.getCellRenderer({
        name: data.standard_hub_name, description: data.standard_hub_key,
      })),
      tooltipValueGetter: (p) => ({
        title: p.data.standard_hub_name,
        subtitle: p.data.standard_hub_key,
      }),
      tooltipComponent: Tooltip,
    },
    {
      field: 'sharing_strategy',
      headerName: 'Sharing Strategy',
      sortable: true,
      cellRenderer: (parameters) => getSharingStrategyLabel(parameters.value),
      tooltipValueGetter: (parameters) => getSharingStrategyLabel(parameters.value),
      width: 450,
    },
    {
      headerName: 'Create Date',
      field: 'createdt',
      tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
      cellRenderer: (parameters) => GenericUtils.formatDateShort(parameters.value),
      width: 150,
    },
  ]);

  const checkUnsavedChanges = (onConfirm) => {
    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const fetchMpi = async () => {
    const mpi = await MpiService.getMpi(clientId);
    const options = await MpiService.getClientIdOptions(clientId);
    setGridData(mpi);
    setClientOptions(options);
  };

  const onSearchChange = (e) => {
    const { target: { value } } = e;
    setSearchText(value);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const onRowClicked = async (params) => {
    setPanel({
      title: 'Create MPI',
      questions: [
        {
          type: 'TEXT',
          label: 'Standard Hub',
          field: 'standard_hub',
          disabled: true,
        },
        {
          label: 'Sharing Strategy',
          field: 'sharing_strategy',
          type: 'DROPDOWN',
          disabled: true,
          options: sharingStrategyOptions,
        },
        {
          type: 'TEXT',
          label: 'Create Date',
          field: 'createdt',
          disabled: true,
          formatter: (value) => GenericUtils.formatDateShort(value),
        },
      ],
      data: params.data,
      viewMode: true,
    });
  };

  const closeSidePanel = () => {
    checkUnsavedChanges(() => {
      setPanel(null);
      setFormDirty(false);
    });
  };

  const onCreateMpi = async () => {
    setPanel({
      title: 'Create MPI',
      questions: [
        {
          label: 'Client ID',
          field: 'client_id',
          type: 'DROPDOWN',
          required: true,
          options: clientOptions,
        },
        {
          label: 'Sharing Strategy',
          field: 'sharing_strategy',
          type: 'DROPDOWN',
          required: true,
          options: sharingStrategyOptions,
        },
      ],
      data: {
        client_id: '',
        sharing_strategy: '',
      },
      submitText: 'Create MPI',
    });
  };

  const onFormChange = async (params) => {
    setFormDirty(params.isDirty);
  };

  const onFormSubmit = async (params) => {
    try {
      setLoading(true);
      await MpiService.createMpi(clientId, params.data);
      toast.success('MPI created succesfully');
      fetchMpi();
      setPanel(null);
      setFormDirty(false);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMpi();
  }, []);

  return (
    <div className="container-padding-40">

      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>MPI</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search MPI..."
            onChange={onSearchChange}
          />
        </div>
        {hubType === 'support-hub' && (
          <div className="col-md-3 text-align-right">
            <Button disabled={clientOptions.length === 0} variant="link" onClick={onCreateMpi}>
              Create MPI
            </Button>
          </div>
        )}
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={GenericUtils.search(gridData, searchText)}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>
      {
        panel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 700 }}
          >
            <SidePanel.Header
              title={panel.title}
              subtitle={panel.subtitle}
            />
            <SidePanel.Content className="no-tabs">
              <JulotaFormNew
                questions={panel.questions}
                data={panel.data}
                isDirty={formDirty}
                viewMode={panel.viewMode}
                isDisabled={panel.disabled}
                submitText={panel.submitText}
                onFormChange={onFormChange}
                onFormSubmit={onFormSubmit}
              />
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
