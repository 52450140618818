import { useEffect, useState } from 'react';
import { setIntervalAsync, clearIntervalAsync } from 'set-interval-async/dynamic';
import {
  Outlet,
  Link,
  useNavigate,
} from 'react-router-dom';
import {
  Link45deg,
  LockFill,
} from 'react-bootstrap-icons';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { useDispatch, useSelector } from 'react-redux';
import { setSession } from 'redux/actions';
import { selectSessionNickName } from 'redux/selectors';
import '../styles/Layout.scss';
import UnlockModal from '../components/UnlockModal';
import AuthService from '../services/AuthService';

export default function AppLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [unlockModalError, setUnlockModalError] = useState(false);
  const sessionNickName = useSelector(selectSessionNickName);

  const logoutUser = async () => {
    try {
      await AuthService.deleteSession();
    } finally {
      sessionStorage.removeItem('tp-access-token');
      dispatch(setSession({}));
      navigate('/login');
    }
  };

  const checkSession = async () => {
    try {
      const serverSession = await AuthService.getSession();
      dispatch(setSession(serverSession));

      if (!serverSession || !serverSession.verification.is_verified) {
        logoutUser();
      }
    } catch (e) {
      logoutUser();
    }
  };

  const handleLock = async (e) => {
    e.preventDefault();
    await AuthService.lockSession();
    checkSession();
  };

  const handleUnlock = async (passcode) => {
    try {
      await AuthService.unlockSession({ passcode });
      checkSession();
    } catch (error) {
      setUnlockModalError(true);
    }
  };

  useEffect(() => {
    checkSession();

    const cs = setIntervalAsync(async () => {
      await checkSession();
    }, 10000);

    return () => clearIntervalAsync(cs);
  }, []);

  return (
    <div className="flex-column">
      <UnlockModal
        error={unlockModalError}
        onSignout={logoutUser}
        onUnlocked={handleUnlock}
      />
      <Navbar sticky="top" className="app-header" variant="dark">
        <Link to="/">
          <img className="logo" src="/julota-logo.png" alt="julota-logo" />
        </Link>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav className="links">
            <NavDropdown title={sessionNickName} menuVariant="dark">
              <NavDropdown.Item onClick={logoutUser}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Link to="" onClick={handleLock} className="links">
            <LockFill size="20" />
          </Link>
          <Link to="" className="links">
            <Link45deg size="20" />
          </Link>
        </Navbar.Collapse>
      </Navbar>
      <div className="flex-row" style={{ maxHeight: 'calc(100vh - 70px)' }}>
        <Outlet />
      </div>
    </div>
  );
}
