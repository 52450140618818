import {
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Subheader from 'components/Subheader';
import SearchBar from 'components/Search/SearchBar';
import SidePanel from 'components/Table/SidePanel';
import Tooltip from 'components/Table/Tooltip';
import GenericUtils from 'utils/GenericUtils';
import PackagesService from '../services/PackagesService';

export default function Packages() {
  const gridRef = useRef();
  const { clientId } = useParams();
  const [packages, setPackages] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [search, setSearch] = useState('');
  const [installing, setInstalling] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Name / Description',
      tooltipValueGetter: (p) => ({
        title: p.data.name,
        subtitle: p.data.description,
      }),
      tooltipComponent: Tooltip,
      cellRenderer: ({ data: { name, description } }) => GenericUtils
        .getCellRenderer({ name, description }),
      sortable: true,
    },
    { headerName: 'Package Key', field: 'package_key', tooltipField: 'package_key' },
    { field: 'tags', tooltipField: 'tags' },
    { headerName: 'Status', field: 'installation_status', tooltipField: 'installation_status' },
  ]);

  const defaultColDef = {
    sortable: true,
    filter: false,
    suppressMovable: true,
    suppressMenu: true,
    resizable: true,
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const fetchGridData = async () => {
    const res = await PackagesService.getClientPackages(clientId);
    setPackages(res);
    setGridData(res);
  };

  const onRowClicked = useCallback(async () => {
    setLoading(true);

    const selectedRows = gridRef.current.api.getSelectedRows();
    const logs = await PackagesService.getClientPackageInstallationLog(
      clientId,
      selectedRows[0].package_key,
    );
    setSelectedRow({
      ...selectedRows[0],
      logs,
      showInstall: logs.filter((i) => i.status === 'installed')?.length === 0,
    });
    setFormDirty(false);

    setLoading(false);
  }, [formDirty]);

  useEffect(() => {
    fetchGridData();
  }, []);

  const installPackage = async (packageKey) => {
    try {
      setInstalling(true);
      setLoading(true);
      await PackagesService.installPackage(clientId, packageKey);
      setInstalling(false);
      toast.success('Package Installed Succesfully!');
    } catch (error) {
      toast.error('Error while installing package');
    } finally {
      setLoading(false);
      const logs = await PackagesService.getClientPackageInstallationLog(
        clientId,
        packageKey,
      );
      setSelectedRow({
        ...selectedRow,
        logs,
        showInstall: logs.filter((i) => i.status === 'installed')?.length === 0,
      });
      setInstalling(false);
      fetchGridData();
    }
  };

  const closeSidePanel = () => {
    setSelectedRow(null);
    setLoading(false);
  };

  useEffect(() => {
    if (search.length > 0) {
      setGridData(GenericUtils.search(packages, search));
    } else {
      setGridData(packages);
    }
  }, [search]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Packages</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Packages..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          ref={gridRef}
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          tooltipShowDelay={1500}
          rowSelection="single"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>
      {
        selectedRow
        && (
          <SidePanel close={closeSidePanel} confirmClosePrompt={formDirty} style={{ width: '800px' }} loading={loading}>
            <SidePanel.Header
              title={selectedRow.name}
              subtitle={selectedRow.package_key}
              style={{ borderBottom: 0 }}
            />
            <SidePanel.Content>
              <div style={{ height: 500, marginTop: 40 }}>
                <div className="label" style={{ fontSize: 12, textTransform: 'uppercase', fontWeight: 200 }}>Installation Logs</div>
                <div className="ag-theme-alpine ag-container">
                  <AgGridReact
                    rowData={selectedRow.logs}
                    columnDefs={[
                      { headerName: 'Status', field: 'status', tooltipField: 'status' },
                      {
                        headerName: 'Date',
                        field: 'install_start',
                        cellRenderer: (parameters) => GenericUtils.formatDateLong(parameters.value),
                        tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
                      },
                    ]}
                    defaultColDef={{
                      sortable: true,
                      filter: false,
                      editable: false,
                      suppressMovable: true,
                      suppressMenu: true,
                      resizable: true,
                    }}
                    tooltipShowDelay={1500}
                    suppressCellFocus="true"
                    onGridReady={onGridReady}
                  />
                </div>
              </div>
              {
                selectedRow.showInstall && (
                  <Button size="sm" disabled={installing} onClick={() => installPackage(selectedRow.package_key)}>Install Package</Button>
                )
              }
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
