/* eslint-disable no-lonely-if */
import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setClient } from 'redux/actions';
import { selectSelectedClientVersionNumber } from 'redux/selectors';
import hash from 'object-hash';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import CustomLink from 'components/CustomLink';
import SidePanel from 'components/Table/SidePanel';
import JulotaForm from 'components/Forms/JulotaForm';
import GenericUtils from 'utils/GenericUtils';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import ComponentPanel from './ComponentPanel';
import ComponentsService from '../services/ComponentsService';
import DatasetsService from '../services/DatasetsService';

import '../styles/Components.scss';
import ClientsService from '../services/ClientsService';

export default function Components() {
  const { clientId, key } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const selectedClientVersionNumber = useSelector(selectSelectedClientVersionNumber);
  const [component, setComponent] = useState();
  const [versionList, setVersionList] = useState([]);
  const [panel, setPanel] = useState(null);
  const [formDirty, setFormDirty] = useState(false);

  const closeSidePanel = () => {
    const onConfirm = () => {
      setPanel(null);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const getComponentVersion = async (versionNumber) => {
    const componentVersion = await ComponentsService.getComponent(key, clientId, versionNumber);

    setComponent(componentVersion);
  };

  const fetchData = async (versionNumber) => {
    const versions = await ComponentsService.getVersionList(key, clientId);
    setVersionList(versions);

    const componentVersion = await ComponentsService.getComponent(key, clientId, versionNumber);
    setComponent(componentVersion);
  };

  const refetchClientData = async () => {
    const res = await ClientsService.getClient(clientId);
    dispatch(setClient(res.data));
  };

  const upsertDraft = async () => {
    try {
      setLoading(true);
      const newComponent = { ...component };
      setComponent(newComponent);

      const res = await ComponentsService.saveComponentDraft(
        key,
        clientId,
        newComponent,
      );

      await fetchData(res.version_number);
      setFormDirty(false);
      setPanel(null);
      await refetchClientData();
      setLoading(false);
      toast.success('Draft Version has been Updated');
    } catch (error) {
      toast.error('Something went wrong. Unable to save draft.');
    }
  };

  const validateAndSaveComponentRow = (id, data) => {
    const errors = [];

    if (data.label === '' && data.header_overwrite === undefined && data.header_overwrite === '') {
      errors.push('Label or Label Overwrite cannot be empty');
    }

    if (data.elements.length === 0) {
      errors.push('You must have at least one element into the container');
    }

    data.elements.forEach((e, index) => {
      if (e.placeholder === undefined || e.placeholder === '') {
        errors.push(`Data Element at index ${index} has empty or missing Placeholder`);
      }
      if (e.data_path === undefined || e.data_path === '') {
        errors.push(`Data Element at index ${index} has empty or missing Data Path`);
      }
      if (e.display_as === undefined || e.display_as === '') {
        errors.push(`Data Element at index ${index} has empty or missing Display As`);
      }
    });

    if (errors.length > 0) {
      toast.error('Missing Required fields, please review form');
      setPanel((prev) => {
        const newPanel = { ...prev };
        newPanel.form.errors = {
          elements: errors,
        };

        return newPanel;
      });
    } else {
      if (component.layout.rows[id]) {
        component.layout.rows[id] = data;
      } else {
        component.layout.rows.push({
          id: component.layout.rows.length + 1,
          ...data,
        });
      }
      upsertDraft();
    }
  };

  const saveComponentPanel = (data) => {
    component.layout.name = data.name;
    component.layout.description = data.description;
    component.layout.title = data.title;
    component.layout.rules = data.rules;
    upsertDraft();
  };

  const createDatasetElement = async (data) => {
    const create = async () => {
      try {
        setLoading(true);
        const res = await DatasetsService.createDatasetElement(clientId, data);

        setFormDirty(false);
        setPanel(null);
        await refetchClientData();
        setLoading(false);
        toast.success('Dataset Element created succesfully');
      } catch (error) {
        toast.error('Something went wrong during creation of dataset element');
      }
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Create Dataset Element?',
          message: 'This action will create a new dataset element.',
          cancelButton: 'Cancel',
          confirmButton: 'Create',
        }}
        confirm={create}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const handleFormActions = async (message) => {
    if (message.type === 'FORM_SUBMITTED') {
      const { id, data } = message;

      if (panel.key === 'component-panel') {
        saveComponentPanel(data);
      }

      if (panel.key === 'component-row-panel') {
        validateAndSaveComponentRow(id, data);
      }

      if (panel.key === 'create-dataset-element-panel') {
        createDatasetElement(data);
      }
    } else if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    }
  };

  const deleteContainer = (item) => {
    toast(
      <ConfirmToast
        config={{
          title: 'Remove Row?',
          message: 'This action will remove the row from the component.',
          cancelButton: 'Cancel',
          confirmButton: 'Delete',
        }}
        confirm={async () => {
          await setComponent((prev) => {
            const newComponent = { ...prev };
            newComponent.layout.rows = newComponent.layout.rows.filter(
              (row) => row.id !== item.id,
            );

            return newComponent;
          });
          upsertDraft();
        }}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const editContainer = async (item, isNew) => {
    const elementsFilter = [];
    component.layout.rows
      .map((r) => r.elements)
      .forEach((e) => e.forEach((e2) => elementsFilter.push(e2.data_path)));

    const componentPanelQuestions = await ComponentsService.getComponentRowForm(
      clientId,
      key,
      item,
      elementsFilter,
    );

    setPanel({
      key: 'component-row-panel',
      width: '90%',
      title: isNew ? 'Add Container' : item.header_overwrite || item.label,
      form: {
        id: isNew ? -1 : component.layout.rows.indexOf(item),
        questions: componentPanelQuestions,
        data: isNew ? {
          uid: uuidv4(),
          index: component.layout.rows.indexOf(item),
          container_type: 'data-elements',
          label: '',
          layout: 'split-25',
          elements: [],
        } : { ...item },
        disabled: component.status !== 'draft',
      },
    });
  };

  const handleDrop = (e) => {
    setComponent((prev) => {
      const newItems = component.layout.rows.slice();
      newItems.push({ label: e.dragData.label, display_as: e.dragData.display_as, uid: uuidv4() });
      const newComponent = { ...prev };
      newComponent.layout.rows = newItems;

      return newComponent;
    });

    upsertDraft();
  };

  const swap = (fromIndex, toIndex, item) => {
    setComponent((prev) => {
      const newItems = component.layout.rows.slice();
      newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, item);
      const newComponent = { ...prev };
      newComponent.layout.rows = newItems;

      return newComponent;
    });

    upsertDraft();
  };

  const editComponent = async (edittingComponent) => {
    const element = edittingComponent.layout;
    element.rules?.map((r) => {
      r.uid = uuidv4();

      return r;
    });

    setPanel({
      key: 'component-panel',
      width: '90%',
      title: element.title,
      subtitle: element.componentKey,
      form: {
        id: uuidv4(),
        questions: await ComponentsService.getComponentForm(clientId, key),
        data: { ...element },
        disabled: component.status !== 'draft',
      },
    });
  };

  const discardDraft = () => {
    const onConfirm = async () => {
      try {
        await ComponentsService.deleteComponentDraft(component.id);
        toast.success('Draft Discarded.');
        fetchData(selectedClientVersionNumber);
      } catch (error) {
        toast.error('Error discarding draft.');
      }
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Discard Changed?',
          message: 'This action will discard all modified component changes.',
          confirmButton: 'Discard',
          cancelButton: 'Cancel',
        }}
        confirm={onConfirm}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const openCreateDatasetPanel = async () => {
    const { phraseCategories, rows } = component.layout;

    setLoading(true);
    const data = await DatasetsService.getClientCategoryPhrases(clientId);

    const categoryOptions = [...new Set(data.filter((r) => phraseCategories.includes(r.category))
      .map((c) => c.category))].map((c) => ({ value: c, label: c }));

    const groupNameOptions = data.filter((r) => phraseCategories.includes(r.category)).map((r) => ({
      value: r.phrase_name,
      label: r.phrase_name,
      conditions: [
        { field: 'category', value: r.category },
      ],
    }));

    setPanel(DatasetsService.getCreateDatasetPanel(categoryOptions, groupNameOptions));
    setLoading(false);
  };

  const handleCallbackMessage = async (message) => {
    switch (message.action) {
      case 'EDIT_CONTAINER':
      case 'VIEW_CONTAINER':
      case 'ADD_CONTAINER':
        editContainer(message.container, message.action === 'ADD_CONTAINER');
        break;
      case 'ADD_DATASET_ELEMENT':
        openCreateDatasetPanel();
        break;
      case 'DELETE_CONTAINER':
        deleteContainer(message.container);
        break;
      case 'EDIT_COMPONENT':
      case 'VIEW_COMPONENT':
        editComponent(message.component);
        break;
      case 'DISCARD_CHANGES':
        discardDraft();
        break;
      case 'UPSERT_DRAFT':
        upsertDraft();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchData(selectedClientVersionNumber);
  }, []);

  return (
    <div className="container-padding-40" style={{ overflowY: 'scroll' }}>
      <div className="row align-items-center mb-3">
        <div className="col">
          <h2>
            <CustomLink to={`/hubs/${clientId}/components`}>Components</CustomLink>
            <span style={{ padding: 10 }}>/</span>
            {key}
          </h2>
        </div>
        <div className="col-md-4 d-flex align-items-baseline me-2">
          <Form.Label className="me-3 label">Version:</Form.Label>
          <Form.Select
            value={component?.version_number || 'current'}
            onChange={(e) => getComponentVersion(e.target.value)}
          >
            {
              versionList.map(
                (v) => <option value={v.value} key={hash(v)}>{v.label}</option>,
              )
            }
          </Form.Select>
        </div>
      </div>
      <div className="row reach-background">
        {
          component && (
          <div className={component.layout.type}>
            <ComponentPanel
              component={component}
              level="component"
              editMode={component.status === 'draft'}
              callbackFunction={handleCallbackMessage}
              swap={swap}
            />
          </div>
          )
        }
      </div>
      {
        panel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            style={{ width: panel.width }}
            loading={loading}
          >
            <SidePanel.Header title={panel.title} subtitle={panel.subtitle} />
            <SidePanel.Content className="no-tabs">
              <JulotaForm
                inputData={panel.form}
                formDirty={formDirty}
                callbackFunction={(message) => handleFormActions(message)}
              />
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
