import { Col, Row } from 'react-bootstrap';

function UsageFieldList({
  usersData = '',
  dateData = 0,
  description = '',
  type,
}) {
  return (
    <div className="usage-field-list">
      <Row>
        <Col xs={8} className="usage-field-list--user">{usersData}</Col>
        <Col xs={4} className="usage-field-list--date">{dateData}</Col>
      </Row>
      {type === 'details' && <small>{description}</small>}

    </div>
  );
}

export default UsageFieldList;
