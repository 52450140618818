import { useMemo } from 'react';
import { Form } from 'react-bootstrap';

export default function ReadOnly(props) {
  const { question, value } = props;

  const displayValue = useMemo(() => {
    if (question.formatter) {
      return question.formatter(value);
    }

    if (Array.isArray(value)) {
      return value.join(', ');
    }

    if (typeof value === 'boolean') {
      return question.options.find((o) => o.value === value).label;
    }

    if (question.selectAllIfEmpty) {
      return question.options.reduce((acc, curr) => {
        if (!acc) {
          return `${curr.value}`;
        }

        return `${acc}, ${curr.value}`;
      }, '');
    }

    return value;
  }, [value, question]);

  return (
    <Form.Group className="readonly">
      <Form.Label className="label">{question.label}</Form.Label>
      <div style={{ marginBottom: 16 }}>
        {displayValue}
      </div>
    </Form.Group>
  );
}
