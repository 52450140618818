/* eslint-disable array-callback-return */
import {
  React,
  useEffect,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setClient } from 'redux/actions';
import {
  selectSelectedClientName,
  selectSelectedClientKey,
  selectSelectedClientVersionNumber,
  selectSelectedClientVersionStatus,
  selectSessionHasBetaAccess,
} from 'redux/selectors';
import classNames from 'classnames';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import {
  GearFill,
  Activity,
  Building,
  People,
  PeopleFill,
  BellFill,
  Server,
  ListCheck,
  PencilSquare,
  PersonLinesFill,
  ReplyFill,
  Paperclip,
  Sliders,
  Stack,
  Bezier,
  Diagram2,
  ClipboardPulse,
  UiChecks,
  Bricks,
  LayoutWtf,
  GearWideConnected,
  Boxes,
  Box,
  BoxArrowDown,
  MicrosoftTeams,
  UniversalAccess,
  Share,
  Hospital,
  BarChart,
} from 'react-bootstrap-icons';
import { Tab, Tabs } from 'react-bootstrap';
import CustomLink from 'components/CustomLink';
import '../styles/Client.scss';
import ClientsService from '../services/ClientsService';

export default function Client() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { clientId } = useParams();

  const [activeTab, setActiveTab] = useState();
  const selectedClientName = useSelector(selectSelectedClientName);
  const selectedClientKey = useSelector(selectSelectedClientKey);
  const selectedClientVersionNumber = useSelector(selectSelectedClientVersionNumber);
  const selectedClientVersionStatus = useSelector(selectSelectedClientVersionStatus);
  const hasBetaAccess = useSelector(selectSessionHasBetaAccess);

  const tabs = [
    {
      key: 'platform',
      name: 'Platform',
      links: [
        { title: 'Dashboard', to: 'dashboard', icon: <Activity /> },
        { title: 'Profile', to: 'profile', icon: <Paperclip /> },
        { title: 'Users', to: 'users', icon: <PeopleFill /> },
        { title: 'Groups', to: 'groups', icon: <People /> },
        { title: 'Teams', to: 'teams', icon: <MicrosoftTeams /> },
        { title: 'Components', to: 'components', icon: <Boxes /> },
        { title: 'Datasets', to: 'datasets', icon: <Server /> },
        { title: 'Notifications', to: 'notifications', icon: <BellFill /> },
        { title: 'Interfaces', to: 'interfaces', icon: <GearWideConnected /> },
        { title: 'Data Exports', to: 'data-exports', icon: <BoxArrowDown /> },
        { title: 'Reports', to: 'reports', icon: <BarChart /> },
        { title: 'Medical Insurers', to: 'medical-insurers', icon: <Hospital /> },
        { title: 'Advanced Configuration', to: 'advanced-configuration', icon: <Sliders /> },
        { title: 'Settings', to: '#', icon: <GearFill /> },
      ],
    }, {
      key: 'application',
      name: 'Application',
      dropdown: {
        options: [
          { key: 'julota-reach', label: 'Julota Reach' },
        ],
      },
      links: [
        { title: 'Versions', to: 'versions', icon: <Bezier /> },
        { title: 'Layouts', to: 'layouts', icon: <LayoutWtf /> },
        { title: 'Modules', to: 'modules', icon: <ListCheck /> },
        { title: 'Packages', to: 'packages', icon: <Box /> },
        { title: 'Assessments', to: 'assessments', icon: <PersonLinesFill /> },
        { title: 'Facesheets', to: 'facesheets', icon: <ClipboardPulse /> },
        { title: 'Forms', to: 'forms', icon: <PencilSquare /> },
        { title: 'Group Services', to: 'group-services', icon: <Diagram2 /> },
        { title: 'Protocols', to: 'protocols', icon: <Bricks /> },
        { title: 'Referrals', to: 'referrals', icon: <ReplyFill /> },
        { title: 'Services', to: 'services', icon: <Stack /> },
        { title: 'Surveys', to: 'surveys', icon: <UiChecks /> },
      ],
    }, {
      key: 'network',
      name: 'Network',
      links: [
        { title: 'Organizations', to: 'organizations', icon: <Building /> },
        { title: 'Hub Relationships', to: 'hub-relations', icon: <Share /> },
        { title: 'MPI', to: 'mpi', icon: <UniversalAccess /> },
      ],
    },
  ];

  const fetchClientData = async () => {
    const res = await ClientsService.getClient(clientId);
    dispatch(setClient(res.data));
  };

  const selectActiveTab = () => {
    tabs.map((t) => {
      t.links.map((l) => {
        if (location.pathname.includes(l.to)) {
          setActiveTab(t.key);
        }
      });
    });
  };

  useEffect(() => {
    fetchClientData();
    selectActiveTab();
  }, []);

  return (
    <>
      <div className="client-nav">
        <div className="client">
          <div className="name">
            {selectedClientName}
          </div>
          <div>{selectedClientKey}</div>
        </div>
        <div className="tabs">
          <Tabs
            onSelect={setActiveTab}
            activeKey={activeTab}
            transition
            fill
          >
            {tabs.map((tab) => (
              <Tab eventKey={tab.key} title={tab.name} key={tab.key}>
                <Tab.Container transition="Fade" unmountOnExit>
                  {
                    tab.dropdown && (
                      <div className="info">
                        <b>Julota Reach</b>
                        <div style={{ fontSize: 8 }}>
                          {`Version: ${selectedClientVersionNumber} (${selectedClientVersionStatus})`}
                        </div>
                      </div>
                    )
                  }

                  <div>
                    {tab.links.map((link) => (
                      <div
                        key={link.title}
                        className={classNames({
                          'disabled-link': link.to === '#',
                        })}
                      >
                        <CustomLink to={link.to}>
                          {link.icon}
                          <span className="nav-title">{link.title}</span>
                        </CustomLink>
                      </div>
                    ))}
                  </div>
                </Tab.Container>
              </Tab>
            ))}
          </Tabs>
        </div>
        <div className="footer">
          <div>Config Admin v1.0.0</div>
          <div>©2015 Julota</div>
        </div>
      </div>
      <div className="flex-row">
        <Outlet />
      </div>
    </>
  );
}
