import { useState } from 'react';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from 'react-bootstrap';
import { ShieldLock } from 'react-bootstrap-icons';
import FormValidation from '../../../utils/FormValidation';

function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState({
    password: '',
    confirmation: '',
    disabled: false,
  });

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setChangePassword({ ...changePassword, disabled: true });
    setLoading(true);
  };

  const valid = () => changePassword.password === changePassword.confirmation
    && changePassword.password.length > 5
    && changePassword.confirmation.length > 5;

  return (
    <Form onSubmit={handleChangePassword}>
      <div className="title">Change Password</div>
      <p>Please enter new password:</p>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text
          id="inputGroup-sizing-sm"
          style={FormValidation.validateStyleColor(changePassword.password, true)}
        >
          <ShieldLock />
        </InputGroup.Text>
        <FormControl
          autoFocus
          type="password"
          placeholder="New Password..."
          value={changePassword?.password}
          onChange={(e) => setChangePassword({ ...changePassword, password: e.target.value })}
          disabled={changePassword.disabled}
          required
          isValid={changePassword.password.length > 5}
        />
        <Form.Control.Feedback type="invalid">
          Invalid characters.
        </Form.Control.Feedback>
      </InputGroup>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text
          id="inputGroup-sizing-sm"
          style={FormValidation.validateStyleColor(changePassword.confirmation, true)}
        >
          <ShieldLock />
        </InputGroup.Text>
        <FormControl
          autoFocus
          type="password"
          placeholder="Confirm New Password..."
          value={changePassword.changePassword}
          onChange={(e) => setChangePassword({ ...changePassword, confirmation: e.target.value })}
          disabled={changePassword.password < 5 || changePassword.disabled}
          required
          isValid={valid()}
        />
        <Form.Control.Feedback type="invalid">
          Invalid characters.
        </Form.Control.Feedback>
      </InputGroup>
      <Button
        block
        size="lg"
        disabled={!valid()}
        type="submit"
      >
        Login
        {loading && <Spinner animation="grow" variant="light" size="sm" />}
      </Button>
    </Form>
  );
}

export default ChangePassword;
