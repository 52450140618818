import { Button } from 'react-bootstrap';

export default function FileUpload() {
  return (
    <div style={{ fontSize: 12 }}>
      <Button size="sm" style={{ fontSize: 12, background: 'red !important' }}>
        Edit documents
      </Button>
      <div>No documents uploaded</div>
    </div>
  );
}
