import { Form } from 'react-bootstrap';

export default function ReachFormCheck(props) {
  const { question, type, inline } = props;

  const getOptionsClass = (maxColumns) => {
    switch (maxColumns) {
      case 1: return 'col-md-12';
      case 2: return 'col-md-6';
      case 3: return 'col-md-4';
      case 4: return 'col-md-3';
      default: return 'col-md-12';
    }
  };

  return (
    <Form style={{ width: '100%', fontSize: '12px' }}>
      <div className="row" style={{ width: '100%' }}>
        {question.options && question.options.map(
          (option) => <div className={getOptionsClass(question.display_max_columns)}><Form.Check type={type} name="test" label={option.label} /></div>,
        )}
      </div>
    </Form>
  );
}
