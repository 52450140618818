import FetchService from 'services/FetchService';

export default class ReportsService {
  static getReports(clientId) {
    return FetchService.fetchData('GET', `reports?client_id=${clientId}`);
  }

  static getReport(clientId, reportId) {
    return FetchService.fetchData('GET', `reports/${reportId}?client_id=${clientId}`);
  }

  static updateReport(clientId, reportId, body) {
    return FetchService.fetchData('PUT', `reports/${reportId}?client_id=${clientId}`, body);
  }
}
