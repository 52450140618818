/* AUTH SELECTORS */
export const selectTwoFactor = (store) => store.coreModule.auth?.twfactor;

export const selectMultiFactorSMS = (store) => store.coreModule.auth?.mfa_sms;

export const selectMultiFactorEmail = (store) => store.coreModule.auth?.mfa_email;

/* SESSION SELECTORS */
export const selectSessionIsSuspended = (store) => store.coreModule.session.suspended;

export const selectSessionIsLocked = (store) => store.coreModule.session.locked;

export const selectSessionNickName = (store) => store.coreModule.session.nickname;

export const selectSessionHasWriteAccess = (store) => store.coreModule.session.roles?.includes('julota-config-admin-write');

export const selectSessionHasBetaAccess = (store) => store.coreModule.session.roles?.includes('julota-config-admin-beta');

/* SELECTED CLIENT SELECTORS */
export const selectSelectedClientName = (store) => store.selectedClient?.name;

export const selectSelectedClientKey = (store) => store.selectedClient?.key;

export const selectSelectedClientTimezone = (store) => store.selectedClient?.timezone;

export const selectSelectedClientVersionNumber = (store) => store.selectedClient?.version_number;

export const selectSelectedClientVersionStatus = (store) => store.selectedClient?.version_status;

export const selectSelectedClientHubType = (store) => store.selectedClient?.hub_type;
