/* eslint-disable react/react-in-jsx-scope */
import FetchService from 'services/FetchService';

export default class LayoutService {
  static getAll(clientId) {
    return FetchService.fetchData('GET', `layouts/all?client_id=${clientId}`);
  }

  static async getLayout(clientId, layoutKey, versionNumber) {
    const versionQuery = versionNumber ? `&version_number=${versionNumber}` : '';
    const response = await FetchService.fetchData('GET', `layouts/${layoutKey}?client_id=${clientId}${versionQuery}`);

    return response;
  }

  static getComponetsToAdd(clientId, entityType, entityKey) {
    return FetchService.fetchData('GET', `layouts/${entityType}/${entityKey}/additionalComponents?client_id=${clientId}`);
  }

  static async getVersionList(clientId, layoutKey) {
    const versionList = await FetchService.fetchData('GET', `layouts/${layoutKey}/versions?client_id=${clientId}`);

    return versionList.map((v) => (
      {
        label: `${v.name} (${v.status})`,
        value: v.version_number,
      }
    ));
  }

  static saveLayoutDraft(clientId, layoutKey, entityType, entityKey, layout, versionNumber) {
    return FetchService.fetchData('POST', `layouts/draft?client_id=${clientId}`, {
      layoutKey,
      entityType,
      entityKey,
      layout,
      versionNumber,
    });
  }

  static publishDraftLayout(id) {
    return FetchService.fetchData('POST', `layouts/draft/${id}/publish`, {});
  }

  static discardDraft(id) {
    return FetchService.fetchData('DELETE', `layouts/draft/${id}`, {});
  }

  static getAddComponentForm(additionalComponents) {
    return [
      {
        label: 'Component Title',
        field: 'component',
        type: 'MULTISELECT',
        isMulti: false,
        placeholder: 'Please search for the component you would like to add',
        options: additionalComponents?.map((i) => ({ value: i.component_key, label: i.name })),
      },
    ];
  }

  static getLayoutFacts(clientId, layoutKey) {
    return FetchService.fetchData('GET', `layouts/${layoutKey}/facts?client_id=${clientId}`);
  }

  static async getLayoutComponentFormQuestions(clientId, layoutKey) {
    const facts = await this.getLayoutFacts(clientId, layoutKey);

    return [
      {
        label: 'Component Title',
        description: 'If not specified then it will used the component title',
        field: 'title',
        disabled: true,
        type: 'TEXT',
      },
      {
        label: 'Title Overwrite',
        description: 'If not specified then it will used the component title',
        field: 'title_overwrite',
        type: 'TEXT',
      },
      {
        label: 'Size',
        field: 'size',
        type: 'DROPDOWN',
        required: true,
        options: [
          { label: 'Full', value: 'full' },
          { label: 'Half', value: 'half' },
        ],
      },
      {
        label: 'Choose Containers',
        field: 'choose_containers',
        description: 'If enable you can pick and choose which containers from this component to show/hide',
        type: 'TOGGLE',
        options: [
          { label: 'No', value: false },
          { label: 'Yes', value: true },
        ],
      },
      {
        label: 'Protected',
        field: 'protected',
        description: 'This apply to all the elements inside of the component',
        type: 'TOGGLE',
        disabled: true,
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      {
        label: 'Readonly',
        field: 'readonly',
        description: 'This apply to all the elements inside of the component',
        type: 'TOGGLE',
        required: true,
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      {
        label: 'Display Rules',
        field: 'display_rules',
        type: 'RULES',
        options: facts,
      },
    ];
  }
}
