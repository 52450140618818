import ModulesService from 'modules/clients/services/ModulesService';
import { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Gear, X } from 'react-bootstrap-icons';
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import classNames from 'classnames';
import ErrorList from './ErrorList';

export default function JsonInput(props) {
  const { question, value, callbackFunction } = props;
  const [selectedValue, setSelectedValue] = useState();
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState();

  const [editingValue, setEditingValue] = useState();

  const onChange = (e) => {
    setEditingValue(e);
  };

  const initiateEditMode = async () => {
    setEditingValue(JSON.stringify(selectedValue, null, 2));
    setEditMode(true);
    callbackFunction({
      target: {
        name: question.field,
        value: selectedValue,
        type: 'NOT_VALID',
      },
    });
  };

  const toggleEditMode = async () => {
    try {
      setErrors(null);
      const jsonObject = JSON.parse(editingValue);
      const validation = await ModulesService.validateJSON(question.field, jsonObject);

      if (validation && validation[0] && !validation[0].valid) {
        setErrors(validation[0]?.errors);
      } else {
        callbackFunction({
          target: {
            name: question.field,
            value: jsonObject,
            type: 'VALID',
          },
        });
        setEditMode(false);
      }
    } catch (error) {
      const errorMessage = error instanceof SyntaxError ? error.message.replace('JSON.parse:', 'Invalid Json:') : 'Invalid Json';
      setErrors([errorMessage]);
    }
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const jsonInputClasses = classNames('json-input', {
    'edit-mode': editMode,
  });

  return (
    <Form.Group className="mb-3">
      <div className="float-right">
        {
          editMode && (
            <Button variant="link" size="sm" onClick={toggleEditMode}>
              <X />
              <span>Finish Configuration</span>
            </Button>
          )
        }
        {
          !editMode && (
            <Button variant="link" size="sm" onClick={initiateEditMode}>
              <Gear />
              <span>Configure</span>
            </Button>
          )
        }
      </div>
      <Form.Label className="label">
        {question.label}
        {question.required && <span>*</span>}
      </Form.Label>
      {errors && errors.length > 0 && <ErrorList errors={errors} />}
      <div className={jsonInputClasses}>
        <CodeMirror
          value={JSON.stringify(selectedValue, null, 2)}
          height="auto"
          width="100%"
          theme="dark"
          onChange={onChange}
          extensions={[json()]}
          readOnly={!editMode}
        />
      </div>
    </Form.Group>
  );
}
