import {
  React,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { setClient } from 'redux/actions';
import { AgGridReact } from 'ag-grid-react';
import { useParams } from 'react-router';
import SearchBar from 'components/Search/SearchBar';
import Tooltip from 'components/Table/Tooltip';
import { toast } from 'react-toastify';
import { Badge, Button } from 'react-bootstrap';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import JulotaForm from 'components/Forms/JulotaForm';
import Subheader from 'components/Subheader';
import GenericUtils from 'utils/GenericUtils';
import SidePanel from 'components/Table/SidePanel';
import ClientsService from '../services/ClientsService';

export default function Versions() {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [form, setForm] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [referrals, setVersions] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [columnDefs] = useState([
    {
      field: 'version_number',
      tooltipField: 'version_number',
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      headerName: 'Version #',
      headerClass: 'text-center',
      width: 50,
    },
    {
      field: 'name',
      headerName: 'Name / Description',
      tooltipValueGetter: (p) => ({
        title: p.data.name,
        subtitle: p.data.description,
      }),
      tooltipComponent: Tooltip,
      cellRenderer: ({ data: { name, description } }) => GenericUtils
        .getCellRenderer({ name, description }),
      wrapText: true,
      autoHeight: true,
    },
    {
      field: 'status',
      tooltipField: 'status',
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      width: 75,
      headerClass: 'text-center',
    },
    {
      field: 'component_changes',
      tooltipField: 'component_changes',
      headerName: 'Component Changes',
      headerClass: 'text-center',
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      cellRenderer: ({ value }) => (value ? value.length : ''),
      width: 75,
    },
    {
      field: 'layout_changes',
      tooltipField: 'layout_changes',
      headerName: 'Layout Changes',
      headerClass: 'text-center',
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      cellRenderer: ({ value }) => (value ? value.length : ''),
      width: 75,
    },
    {
      field: 'publishdt',
      tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
      headerName: 'Publish Date',
      width: 75,
      headerClass: 'text-center',
      cellRenderer: (params) => GenericUtils.formatDateShort(params.value),
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
    },
    {
      field: 'createdt',
      tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
      headerName: 'Create Date',
      width: 75,
      headerClass: 'text-center',
      cellRenderer: (params) => GenericUtils.formatDateShort(params.value),
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
    },
  ]);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const fetchGridData = async () => {
    const res = await ClientsService.getVersions(clientId);

    setVersions(res);
    setGridData(res);
  };

  const onRowClicked = useCallback((params) => {
    const onConfirm = async () => {
      setSelectedRow(params.api.getSelectedRows()[0]);
      setForm({
        questions: [
          {
            label: 'Name',
            field: 'name',
            type: 'TEXT',
          },
          {
            label: 'Description of Changes',
            field: 'description',
            type: 'TEXTAREA',
          },
          {
            label: 'Component Changes',
            field: 'component_changes',
            type: 'MULTISELECT',
            disabled: true,
            placeholder: 'No Changes Yet...',
          },
          {
            label: 'Layout Changes',
            field: 'layout_changes',
            type: 'MULTISELECT',
            disabled: true,
            placeholder: 'No Changes Yet...',
          },
        ],
        submitText: 'Publish',
        showDiscard: params.data.status === 'draft',
        data: params.api.getSelectedRows()[0],
        disabled: params.data.status === 'published',
      });
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  }, [formDirty]);

  const closeSidePanel = () => {
    const onConfirm = () => {
      setForm(null);
      setSelectedRow(null);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const refetchClientData = async () => {
    const res = await ClientsService.getClient(clientId);
    dispatch(setClient(res.data));
  };

  const createAppVersion = (name, description) => {
    const createVersion = async () => {
      try {
        await ClientsService.createClientAppVersion(clientId, name, description);
        setForm(null);
        setSelectedRow(null);
        setFormDirty(false);
        refetchClientData();
        fetchGridData();
        toast.success('Draft Version Created.');
        setCreating(false);
      } catch (error) {
        toast.error(error);
      }
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Create App Version?',
          message: 'This action will create a draft app version.',
          cancelButton: 'Cancel',
          confirmButton: 'Create',
        }}
        confirm={createVersion}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const startCreatingAppVersion = () => {
    setForm({
      questions: [
        {
          label: 'Name',
          field: 'name',
          type: 'TEXT',
        },
        {
          label: 'Description',
          field: 'description',
          type: 'TEXTAREA',
        },
      ],
      submitText: 'Create Draft',
      data: { name: '', description: '' },
    });

    setCreating(true);
  };

  const publishAppVersion = useCallback(() => {
    const publish = async () => {
      try {
        await ClientsService.publishClientAppVersion(
          clientId,
          selectedRow.version_number,
          selectedRow.name,
          selectedRow.description,
        );

        setForm(null);
        setSelectedRow(null);
        setFormDirty(false);
        await fetchGridData();

        toast.success('Your Version has been published.');
      } catch (error) {
        toast.error('Couldn\'t publish Version');
      }
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Publish Application Version',
          message: 'By clicking the publish button all components and layouts tagged as modified will get published',
          cancelButton: 'Cancel',
          confirmButton: 'Publish',
        }}
        confirm={publish}
        cancel={() => { }}
      />,
      GenericUtils.confirmToastOptions,
    );
  }, [selectedRow]);

  const discardAppVersion = async () => {
    const discard = async () => {
      try {
        await ClientsService.discardClientAppVersion(clientId, selectedRow.version_number);
        setForm(null);
        setSelectedRow(null);
        setFormDirty(false);
        fetchGridData();
        await refetchClientData();

        toast.success('Your Draft version has been discarded.');
      } catch (error) {
        toast.error('Couldn\'t discard draft Version');
      }
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Discard Draft Version',
          message: 'By clicking discard, you will delete all the unsaved changes to components and layouts tagged as modified',
          confirmButton: 'Discard',
          cancelButton: 'Cancel',
        }}
        confirm={discard}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'DISCARD') {
      await discardAppVersion();
    } else if (message.type === 'FORM_SUBMITTED') {
      const { data } = message;

      setLoading(true);

      if (creating) {
        createAppVersion(data.name, data.description);
      } else {
        try {
          await publishAppVersion();
        } catch (error) {
          toast.error(error);
        }
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGridData();
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setGridData(GenericUtils.search(referrals, searchText));
    } else {
      setGridData(referrals);
    }
  }, [searchText]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Versions</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Versions..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="col-md-3 text-align-right">
          <Button
            variant="link"
            onClick={startCreatingAppVersion}
            disabled={gridData.some((v) => v.status === 'draft')}
          >
            Create Draft Version
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
        />
      </div>

      {
        form && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 650 }}
          >
            <SidePanel.Header
              title={creating ? 'Create App Version Draft' : selectedRow?.name}
              subtitle={!creating && `Created on ${GenericUtils.formatDateShort(selectedRow?.createdt)}`}
            >
              <div className="float-right">
                {
                  !creating && (
                    selectedRow?.status === 'draft'
                      ? (
                        <Badge bg="warning" text="dark">Draft</Badge>
                      )
                      : (
                        <Badge bg="success">Published</Badge>
                      )
                  )
                }
              </div>
            </SidePanel.Header>

            <SidePanel.Content className="no-tabs">
              <JulotaForm
                inputData={form}
                callbackFunction={(message) => handleCallback(message)}
                formDirty={formDirty}
              />
            </SidePanel.Content>

          </SidePanel>
        )
      }

    </div>
  );
}
