import {
  React,
  useState,
  useEffect,
} from 'react';
import GenericUtils from 'utils/GenericUtils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import SidePanel from 'components/Table/SidePanel';
import Subheader from 'components/Subheader';
import SearchBar from 'components/Search/SearchBar';
import JulotaForm from 'components/Forms/JulotaForm';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import ReportsService from '../services/ReportsService';

export default function Reports() {
  const { clientId } = useParams();
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [panel, setPanel] = useState(null);
  const [formDirty, setFormDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [columnDefs] = useState([
    {
      field: 'name',
      sortable: true,
      tooltipField: 'name',
    },
    {
      field: 'display',
      headerName: 'Description',
      sortable: true,
      cellRenderer: ({ data: { display, description } }) => GenericUtils
        .getCellRenderer({ name: display, description }),
      tooltipField: 'description',
    },
    {
      field: 'type',
      sortable: true,
      tooltipField: 'type',
    },
    {
      field: 'category',
      sortable: true,
      tooltipField: 'category',
    },
    {
      field: 'active',
      sortable: true,
      cellRenderer: (params) => (params.value ? 'Active' : 'Inactive'),
      cellStyle: (params) => ({ ...GenericUtils.statusCellStyle(params), textAlign: 'left' }),
      tooltipValueGetter: (p) => (p.data.active ? 'Active' : 'Inactive'),
    },
  ]);

  const checkUnsavedChanges = (onConfirm) => {
    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const fetchReports = async () => {
    const reports = await ReportsService.getReports(clientId);
    setGridData(reports);
  };

  const onSearchChange = (e) => {
    const { target: { value } } = e;
    setSearchText(value);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const onRowClicked = async (params) => {
    const { id } = params.data;

    if (!panel || panel?.data.id !== id) {
      setPanel({});
      const report = await ReportsService.getReport(clientId, id);
      setPanel(report);
    }
  };

  const closeSidePanel = () => {
    checkUnsavedChanges(() => {
      setPanel(null);
      setFormDirty(false);
    });
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    }

    if (message.type === 'FORM_SUBMITTED') {
      const { data } = message;

      try {
        setLoading(true);
        await ReportsService.updateReport(clientId, data.id, data);

        toast.success('Changes Saved!');
        setFormDirty(false);
        fetchReports();
      } catch (error) {
        toast.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  return (
    <div className="container-padding-40">

      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Reports</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Reports..."
            onChange={onSearchChange}
          />
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={GenericUtils.search(gridData, searchText)}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>
      {
        panel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 650 }}
          >
            {
              panel.data && (
                <>
                  <SidePanel.Header
                    title={panel.data.name}
                  />

                  <SidePanel.Content className="no-tabs">
                    <JulotaForm
                      inputData={panel}
                      callbackFunction={(message) => handleCallback(message)}
                      formDirty={formDirty}
                    />
                  </SidePanel.Content>
                </>
              )
            }
          </SidePanel>
        )
      }
    </div>
  );
}
