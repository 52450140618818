import {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import {
  Alert,
  Tab,
  Tabs,
  Badge,
  Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import GenericUtils from 'utils/GenericUtils';
import SearchBar from 'components/Search/SearchBar';
import Subheader from 'components/Subheader';
import SidePanel from 'components/Table/SidePanel';
import JulotaForm from 'components/Forms/JulotaForm';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import DatasetsHelper from '../helpers/DatasetsHelper';
import DatasetsService from '../services/DatasetsService';

export default function Datasets() {
  const helpers = new DatasetsHelper();
  const initialState = helpers.setInitialStateDatasets(
    (params) => GenericUtils.formatDateShort(params.value),
  );
  const gridRef = useRef();
  const { clientId } = useParams();
  const [search, setSearch] = useState('');
  const [datasets, setDatasets] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [formDirty, setFormDirty] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [panel, setPanel] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const [columnDefs] = useState(initialState.columnDefs);

  const defaultColDef = {
    sortable: true,
    filter: true,
    suppressMovable: true,
    suppressMenu: true,
    resizable: true,
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const fetchGridData = async () => {
    const res = await DatasetsService.getDatasets(clientId);
    setDatasets(res);

    if (search.length > 0) {
      setGridData(GenericUtils.search(res, search));
    } else {
      setGridData(res);
    }
  };

  const getPillColor = (scope) => {
    switch (scope) {
      case 'Shared': return 'primary';
      case 'Customized': return 'secondary';
      case 'Diverged': return 'info';
      default: return '';
    }
  };

  const validateAndInitialize = (tabIndex) => {
    const onConfirm = async () => {
      const selectedRows = gridRef.current.api.getSelectedRows();

      // eslint-disable-next-line no-nested-ternary
      const p = tabIndex !== null && selectedRows.length > 0 ? {
        phrase_id: selectedRows[0].phrase_id,
        phrase_variable_id: selectedRows[0].phrase_variable_id,
        title: selectedRows[0].variable_header,
        is_procedure_driven: selectedRows[0].is_procedure_driven,
        subtitle: selectedRows[0].data_path,
        forms: await DatasetsService.getDatasetTabs(
          clientId,
          selectedRows[0]?.phrase_id,
          selectedRows[0]?.phrase_variable_id,
        ),
        scope: selectedRows[0].scope,
      } : tabIndex !== null && selectedRows.length === 0 && panel ? panel : null;

      setPanel(p);
      setActiveTab(tabIndex);
      setFormDirty(false);
      setFormErrors(null);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const selectDataset = useCallback(() => {
    setLoading(true);
    validateAndInitialize(0);
    setLoading(false);
  }, [formDirty]);

  const patchDataset = async (message) => {
    try {
      let { data } = message;
      const { id, initialFormData } = message;

      setFormErrors(null);
      setLoading(true);

      const defaultOption = data.options?.find((opt) => opt.is_default === true) ?? null;

      data = { default_variable_value: defaultOption?.id || null, ...data };

      const { newPhraseId } = await DatasetsService.updateDataset(
        clientId,
        id,
        // GenericUtils.getObjectsDifference(initialFormData, data),
        data,
      );

      fetchGridData();
      setPanel({
        ...panel,
        phrase_id: newPhraseId || panel.phrase_id,
        forms: await DatasetsService.getDatasetTabs(
          clientId,
          newPhraseId || panel.phrase_id,
          panel.phrase_variable_id,
        ),
        scope: newPhraseId ? 'Customized' : panel.scope,
      });

      setFormDirty(false);
      toast.success('Changes Saved!');
    } catch (errors) {
      if (Array.isArray(errors)) {
        setFormErrors({ options: errors });
      } else {
        toast.error(errors);
      }
    }

    setLoading(false);
  };

  const divergeClientVariable = async () => {
    toast(
      <ConfirmToast
        config={{
          title: 'Confirm',
          message: 'This action is non reversible, once you hit save this variable will no longer be kept in sync with the global variable, please click confirm to proceed or cancel',
          cancelButton: 'Cancel',
          confirmButton: 'Diverge',
        }}
        confirm={async () => {
          try {
            setLoading(true);

            const { newPhraseId, newVariableId } = await DatasetsService.divergeFromShared(
              clientId,
              panel.forms[1].id.split('_')[2],
              panel.subtitle,
            );
            fetchGridData();
            setPanel({
              ...panel,
              phrase_id: newPhraseId,
              phrase_variable_id: newVariableId,
              forms: await DatasetsService.getDatasetTabs(
                clientId,
                newPhraseId,
                newVariableId,
              ),
              scope: 'Diverged',
            });

            setLoading(false);
          } catch (error) {
            setLoading(false);
          }
        }}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const createDatasetElement = async (data) => {
    const create = async () => {
      try {
        setLoading(true);
        const res = await DatasetsService.createDatasetElement(clientId, data);

        setFormDirty(false);
        setPanel(null);
        await fetchGridData();
        setLoading(false);
        toast.success('Dataset Element created succesfully');
      } catch (error) {
        toast.error('Something went wrong during creation of dataset element');
      }
    };

    toast(
      <ConfirmToast
        config={{
          title: 'Create Dataset Element?',
          message: 'This action will create a new dataset element.',
          cancelButton: 'Cancel',
          confirmButton: 'Create',
        }}
        confirm={create}
      />,
      GenericUtils.confirmToastOptions,
    );
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    }
    if (message.type === 'FORM_SUBMITTED') {
      if (panel.key === 'create-dataset-element-panel') {
        createDatasetElement(message.data);
      } else {
        patchDataset(message);
      }
    }
  };

  const openCreatedatasetPanel = async (message) => {
    setLoading(true);
    const data = await DatasetsService.getClientCategoryPhrases(clientId);

    const categoryOptions = [...new Set(data.map((c) => c.category))].map((c) => ({
      value: c,
      label: c,
    }));

    const groupNameOptions = data.map((r) => ({
      value: r.phrase_name,
      label: r.phrase_name,
      conditions: [
        { field: 'category', value: r.category },
      ],
    }));

    setPanel(DatasetsService.getCreateDatasetPanel(categoryOptions, groupNameOptions));
    setLoading(false);
  };

  useLayoutEffect(() => {
    fetchGridData();
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      setGridData(GenericUtils.search(datasets, search));
    } else {
      setGridData(datasets);
    }
  }, [search]);

  return (
    <div className="container-padding-40">

      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Datasets</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-6">
          <SearchBar
            placeholder="Search Datasets..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-md-3 text-align-right">
          <Button
            variant="link"
            onClick={openCreatedatasetPanel}
          >
            Create Dataset Element
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          ref={gridRef}
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          suppressCellFocus="true"
          rowHeight="65"
          suppressScrollOnNewData="true"
          onRowClicked={selectDataset}
          onGridReady={onGridReady}
        />
      </div>
      {
        panel
        && (
          <SidePanel close={() => validateAndInitialize(null)} confirmClosePrompt={formDirty} style={{ width: '60%' }} loading={loading}>
            <SidePanel.Header
              title={panel.title}
              subtitle={panel.subtitle}
              style={{ borderBottom: 0 }}
            >
              {
                panel.scope && (
                  <div className="float-right">
                    <Badge pill bg={getPillColor(panel.scope)}>{panel.scope}</Badge>
                  </div>
                )
              }
            </SidePanel.Header>

            <SidePanel.Content>
              {
                panel.forms && (
                  <div className="tabs-container">
                    <Tabs
                      onSelect={validateAndInitialize}
                      activeKey={activeTab}
                      defaultActiveKey={0}
                      mountOnEnter
                      unmountOnExit
                    >
                      {
                        panel.forms.map((form, index) => form.data && (
                          <Tab
                            eventKey={index}
                            title={form.name}
                            key={form.id}
                          >
                            {
                              form.name === 'Options' && panel.scope !== 'Diverged' && panel.is_procedure_driven !== true && (
                                <div style={{ marginBottom: 10, textAlign: 'right' }}>
                                  <Button variant="link" size="sm" onClick={() => divergeClientVariable()}>
                                    Diverge from Shared/Customized
                                  </Button>
                                </div>
                              )
                            }
                            {
                              form.name === 'Options' && panel.scope !== 'Diverged' && panel.is_procedure_driven && (
                                <Alert variant="primary">
                                  Unable to Diverge a data element with dynamic values.
                                </Alert>
                              )
                            }
                            <JulotaForm
                              inputData={{
                                ...form,
                                errors: formErrors,
                                messages: formErrors && Object.keys(formErrors).length > 0 ? [{
                                  type: 'danger', text: 'There are errors in the form, please try again.',
                                }] : [],
                              }}
                              callbackFunction={(message) => handleCallback(message)}
                              formDirty={formDirty}
                            />
                          </Tab>
                        ))
                      }
                    </Tabs>
                  </div>
                )
              }
              {
                panel.form && (
                  <JulotaForm
                    inputData={panel.form}
                    formDirty={formDirty}
                    callbackFunction={(message) => handleCallback(message)}
                  />
                )
              }
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
