import GenericUtils from 'utils/GenericUtils';
import Tooltip from 'components/Table/Tooltip';

export default class DatasetsHelper {
  constructor() {
    this.statusStyle = (params) => {
      switch (params.value) {
        case 'active': return { color: 'var(--color-active)' };
        case 'inactive': return { color: 'var(--color-inactive)' };
        case 'archived': return { color: 'var(--color-archived)' };
        case 'deleted': return { color: 'var(--color-deleted)' };
        default: return { color: 'var(--color-black)' };
      }
    };
    this.DatasetsDef = {
      columnDefs: [
        {
          headerName: 'Data Element',
          field: 'variable_header',
          /* eslint-disable camelcase */
          cellRenderer: ({ data: { variable_header, data_path } }) => GenericUtils
            .getCellRenderer({ name: variable_header, description: data_path }),
          /* eslint-enable camelcase */
          width: '600',
          tooltipValueGetter: (p) => ({
            title: p.data.variable_header,
            subtitle: p.data.data_path,
          }),
          tooltipComponent: Tooltip,
        },
        {
          headerName: 'Scope', field: 'scope', width: '150', tooltipField: 'scope',
        },
        { headerName: 'Type', field: 'type', tooltipField: 'type' },
        {
          headerName: 'Status',
          field: 'variable_status',
          width: '100',
          cellStyle: this.statusStyle,
          cellClass: 'capitalize',
          tooltipField: 'variable_status',
        },
        {
          headerName: 'Created Date',
          field: 'createdt',
          cellRenderer: this.formatDateShort,
          tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
        },
      ],
    };

    this.results = {};
    this.setInitialStateDatasets = this.setInitialStateDatasets.bind(this);
  }

  setInitialStateDatasets(Element) {
    this.results = this.DatasetsDef;
    const targetResult = this.results.columnDefs;
    let target = {};
    let index = 0;
    this.results.columnDefs.forEach((item, j) => {
      if (item.field === 'createdt') {
        target = item;
        index = j;
      }
    });
    target.cellRenderer = Element;
    targetResult[index] = target;
    this.results.columnDefs = targetResult;

    return this.results;
  }
}
