import {
  React,
  useState,
  useEffect,
  useCallback,
} from 'react';
import cronstrue from 'cronstrue';
import GenericUtils from 'utils/GenericUtils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import SidePanel from 'components/Table/SidePanel';
import Subheader from 'components/Subheader';
import SearchBar from 'components/Search/SearchBar';
import JulotaForm from 'components/Forms/JulotaForm';
import Tooltip from 'components/Table/Tooltip';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import NotificationsService from '../services/NotificationsService';

export default function Notifications() {
  const { clientId } = useParams();
  const [notifications, setNotifications] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [form, setForm] = useState();
  const [formDirty, setFormDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [columnDefs] = useState([
    {
      field: 'name',
      tooltipValueGetter: (p) => ({
        title: p.data.name,
        subtitle: p.data.description,
      }),
      tooltipComponent: Tooltip,
      cellRenderer: ({ data: { name, description } }) => GenericUtils
        .getCellRenderer({ name, description }),
      headerName: 'Name / Description',
      sortable: true,
    },
    {
      field: 'type',
      tooltipField: 'type',
      sortable: true,
    },
    {
      field: 'interval',
      tooltipValueGetter: (p) => cronstrue.toString(p.value),
      cellRenderer: (params) => cronstrue.toString(params.value),
    },
    {
      field: 'status',
      tooltipField: 'status',
      headerName: 'Status',
      cellRenderer: (params) => GenericUtils.capitalizeFirstLetter(params.value),
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      width: 75,
      headerClass: 'text-center',
    },
    {
      headerName: 'Created Date',
      field: 'createdt',
      tooltipValueGetter: (p) => GenericUtils.formatDateShort(p.value),
      cellRenderer: (params) => GenericUtils.formatDateShort(params.value),
    },
  ]);

  const deletedStatusToastConfig = {
    title: 'Delete Notification',
    message: 'Are you sure you want to delete this notification? It cannot be restored.',
    cancelButton: 'Continue Editing',
    confirmButton: 'Delete',
  };

  const fetchGridData = async () => {
    const res = await NotificationsService.getClientNotifications(clientId);
    setNotifications(res);
    setGridData(res);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const onRowClicked = useCallback((params) => {
    const onConfirm = async () => {
      const { cron_id: cronId, name } = params.api.getSelectedRows()[0];

      const notification = await NotificationsService.getClientNotification(clientId, {
        clientId,
        cronId,
        name,
      });

      setForm(notification);
      setLoading(true);
      setFormDirty(false);
      setLoading(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  }, [formDirty]);

  const closeSidePanel = () => {
    const onConfirm = () => {
      setForm(null);
      setFormDirty(false);
    };

    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const reallySave = async (data) => {
    try {
      await NotificationsService.updateClientNotification(clientId, {
        clientId,
        ...data,
      });

      toast.success('Changes Saved!');
      setFormDirty(false);
      fetchGridData();
    } catch (error) {
      toast.error(error);
    }

    setLoading(false);
  };

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'FORM_SUBMITTED') {
      const { data } = message;

      setLoading(true);

      if (data.interval === 'custom') {
        cronstrue.toString(data.custom_interval);
      } else {
        delete data.custom_interval;
      }

      if (data.status === 'deleted') {
        toast(
          <ConfirmToast
            config={deletedStatusToastConfig}
            confirm={() => {
              reallySave(data);
            }}
            cancel={() => {
              setLoading(false);
            }}
          />,
          GenericUtils.confirmToastOptions,
        );
      } else {
        reallySave(data);
      }
    }
  };

  useEffect(() => {
    fetchGridData();
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setGridData(GenericUtils.search(notifications, searchText));
    } else {
      setGridData(notifications);
    }
  }, [searchText]);

  return (
    <div className="container-padding-40">

      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Notifications</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Notifications..."
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={onRowClicked}
          onGridReady={onGridReady}
        />
      </div>

      {
        form && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: 650 }}
          >
            <SidePanel.Header
              title={form.data.name}
              subtitle={form.data.description}
            />

            <SidePanel.Content className="no-tabs">
              <JulotaForm
                inputData={form}
                callbackFunction={(message) => handleCallback(message)}
                formDirty={formDirty}
              />
            </SidePanel.Content>

          </SidePanel>
        )
      }
    </div>
  );
}
