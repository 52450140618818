import { Placeholder } from 'react-bootstrap';
import hash from 'object-hash';

function PlaceholderSkeleton({ rows, className, width }) {
  const setRows = () => {
    const rw = [];
    while (rw.length !== rows) {
      rw.push(Math.floor(Math.random() * (12 - 6) + 6));
    }

    return rw;
  };

  return (
    <Placeholder as="p" animation="glow">
      {setRows().map((item, i) => (
        <Placeholder xs={width || item} key={hash(i)} />
      ))}
    </Placeholder>
  );
}

export default PlaceholderSkeleton;
