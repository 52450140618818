import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { Plus } from 'react-bootstrap-icons';
import SidePanel from 'components/Table/SidePanel';
import JulotaForm from 'components/Forms/JulotaForm';
import SearchBar from 'components/Search/SearchBar';
import Subheader from 'components/Subheader';
import PlaceholderSkeleton from 'components/Skeleton/PlaceholderSkeleton';
import GenericUtils from 'utils/GenericUtils';
import ClientsService from '../services/ClientsService';

export default function Clients() {
  const gridRef = useRef();
  const [create, setCreate] = useState(false);
  const [clients, setClients] = useState();
  const [gridData, setGridData] = useState([]);
  const [form, setForm] = useState();
  const [formDirty, setFormDirty] = useState(false);
  const [search, setSearch] = useState('');
  const [sidepanelLoading, setSidepanelLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState();
  const navigate = useNavigate();

  const [columnDefs] = useState([
    {
      field: 'name', width: '300', headerName: 'Description', tooltipField: 'name',
    },
    { field: 'abbreviation', headerName: 'Name', tooltipField: 'abbreviation' },
    { field: 'key', tooltipField: 'key' },
    { field: 'timezone', tooltipField: 'timezone' },
    {
      headerName: 'Status',
      field: 'active',
      headerClass: 'text-center',
      cellRenderer: (params) => (params.value ? 'Active' : 'Inactive'),
      cellStyle: (params) => GenericUtils.statusCellStyle(params),
      tooltipValueGetter: (p) => (p.data.active ? 'Active' : 'Inactive'),
    },
  ]);

  const fetchData = async () => {
    const gData = await ClientsService.getClients();
    const createFormData = await ClientsService.getClient();
    setClients(gData);
    setGridData(gData);
    setForm(createFormData);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    navigate(`/hubs/${selectedRows[0].id}/profile`);
  }, []);

  const handleCallback = async (message) => {
    if (message.type === 'FORM_DIRTY') {
      setFormDirty(message.isDirty);
    } else if (message.type === 'FORM_SUBMITTED') {
      const { data } = message;

      setSidepanelLoading(true);

      try {
        setFormDirty(false);
        const { client_id: newClientId } = await ClientsService.postClient(data);

        toast.success('Client Added Successfully');
        navigate(`/hubs/${newClientId}/profile`);
      } catch (error) {
        toast.error(error);
        setFormDirty(true);
      }

      setSidepanelLoading(false);
    }
  };

  useEffect(() => {
    setTableLoading(true);
    fetchData();
    setTableLoading(false);
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      setGridData(GenericUtils.search(clients, search));
    } else {
      setGridData(clients);
    }
  }, [search]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Hubs</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar
            placeholder="Search Hubs..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-md-3 text-align-right">
          {
            form ? (
              <Button variant="link" size="sm" onClick={() => setCreate(true)}>
                <Plus />
                Add Hub
              </Button>
            ) : (
              <PlaceholderSkeleton rows={1} />
            )
          }
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        {tableLoading
          ? <PlaceholderSkeleton rows={8} />
          : (
            <AgGridReact
              ref={gridRef}
              rowData={gridData}
              columnDefs={columnDefs}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
              }}
              tooltipShowDelay={1500}
              rowSelection="single"
              suppressContextMenu="true"
              onSelectionChanged={onSelectionChanged}
              onGridReady={onGridReady}
              suppressScrollOnNewData="true"
            />
          )}
      </div>

      {
        create && (
          <SidePanel
            confirmClosePrompt={formDirty}
            loading={sidepanelLoading}
            close={() => setCreate(false)}
          >
            <SidePanel.Header
              title="Add Hub"
              style={{ minHeight: 'unset' }}
            />
            <SidePanel.Content className="no-tabs">
              <JulotaForm
                inputData={form}
                callbackFunction={(message) => handleCallback(message)}
                formDirty={formDirty}
              />
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
