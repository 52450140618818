import { React } from 'react';
import { Link } from 'react-router-dom';
import {
  BarChartLineFill,
  PeopleFill,
  Diagram3Fill,
  GearFill,
  Building,
} from 'react-bootstrap-icons';
import classNames from 'classnames';
import '../styles/Home.scss';

export default function Home() {
  const text = 'This is some text of the description that will go into each one of the diff box';

  const links = [
    { title: 'Client Usage', to: '/client-usage', icon: <BarChartLineFill size="50" /> },
    { title: 'Hubs', to: '/hubs', icon: <Diagram3Fill size="50" /> },
    { title: 'Users', to: '/users', icon: <PeopleFill size="50" /> },
    { title: 'Orgs', to: '#', icon: <Building size="50" /> },
    // { title: 'Invoices', to: '#', icon: <CreditCard size="50" /> },
    // { title: 'Contracts', to: '#', icon: <PenFill size="50" /> },
    { title: 'Settings', to: '#', icon: <GearFill size="50" /> },
  ];

  return (
    <div className="home-page">
      <div className="content">
        <div className="row">
          {links.map((link) => (
            <Link
              to={link.to}
              key={link.title}
              className="col-md-4"
            >
              <div className={classNames('card-box', {
                'disabled-link': link.to === '#',
              })}
              >
                <div>{link.icon}</div>
                <div className="title">{link.title}</div>
                <div className="description">{text}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="footer">
        <div>Config Admin v1.0.0</div>
        <div>©2015 Julota</div>
      </div>
    </div>
  );
}
