export default function DescriptionCellRenderer(props) {
  const { name, description } = props;

  return (
    <div className="description-cell">
      <div className="description-cell-title">{name}</div>
      <div className="description-cell-subtitle">{description}</div>
    </div>
  );
}
