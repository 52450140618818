import {
  useEffect,
  useState,
} from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import SidePanel from 'components/Table/SidePanel';
import ClientUsagesHelper from '../helpers/ClientUsagesHelper';
import UsageDetailsData from './UsageDetailsData';
import UsageActives from './UsageActives';
import ClientUsageService from '../services/ClientUsageService';

export default function UsageSidePanel({
  id = '',
  name = '',
  closeSidePanel,
  loading,
  setLoading,
}) {
  const [clientUsage, setClientUsage] = useState();
  const [clientDetailUsage, setClientDetailUsage] = useState([]);
  const [data, setData] = useState({});
  const [dataDetails, setDataDetails] = useState({});

  useEffect(() => {
    ClientUsageService.getClientUsages(id)
      .then((res) => {
        setClientUsage(res);
      });
    // ClientUsageService.getClientUsagesDetails(id)
    //   .then((res) => {
    //     setClientDetailUsage(res);
    //   });
  }, [id]);

  useEffect(() => {
    if (clientUsage && clientDetailUsage) {
      setData(ClientUsagesHelper.mapClientData(clientUsage, 'actives'));
      setDataDetails(ClientUsagesHelper.mapClientData(clientDetailUsage, 'details'));
      setLoading(false);
    }
  }, [clientUsage, clientDetailUsage]);

  const usageSetDetailsSearch = (e) => {
    const searchs = ClientUsagesHelper.usageSearch(clientDetailUsage, e.target.value.toLowerCase());
    setDataDetails(ClientUsagesHelper.mapClientData(searchs, 'details'));
  };

  return (
    <SidePanel close={closeSidePanel} loading={loading}>
      <SidePanel.Header title={name} close={closeSidePanel} style={{ borderBottom: 0 }} />
      <SidePanel.Content>
        <div className="tabs-container">
          <Tabs
            transition
            className="mb-3"
          >
            <Tab eventKey="active" title="Actives Patients">
              <Tab.Container transition="Fade" unmountOnExit>
                <UsageActives data={data} loading={loading} />
              </Tab.Container>
            </Tab>
            <Tab eventKey="details" title="Details">
              Under Development
              {/* <Tab.Container transition="Fade" unmountOnExit>
                <UsageDetailsData
                  dataDetails={dataDetails}
                  usageSetDetailsSearch={usageSetDetailsSearch}
                  loading={loading}
                />
              </Tab.Container> */}
            </Tab>
          </Tabs>
        </div>
      </SidePanel.Content>
    </SidePanel>

  );
}
