import {
  React,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useBlocker } from 'hooks/useBlocker';
import { AgGridReact } from 'ag-grid-react';
import { useParams } from 'react-router';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SearchBar from 'components/Search/SearchBar';
import Subheader from 'components/Subheader';
import SidePanel from 'components/Table/SidePanel';
import JulotaFormNew from 'components/Forms/JulotaFormNew';
import Tooltip from 'components/Table/Tooltip';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import UsersService from 'modules/users/services/UsersService';
import GenericUtils from 'utils/GenericUtils';
import OrganizationService from '../services/OrganizationService';

export default function Organizations() {
  const { clientId } = useParams();
  const [orgs, setOrgs] = useState('');
  const [clientUsers, setClientUsers] = useState();
  const [gridData, setGridData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [formDirty, setFormDirty] = useState(false);
  const [panel, setPanel] = useState();
  const [loading, setLoading] = useState(false);

  const [columnDefs] = useState([
    {
      field: 'name',
      headerName: 'Name / Description',
      tooltipValueGetter: (p) => ({
        title: p.data.name,
        subtitle: p.data.description,
      }),
      tooltipComponent: Tooltip,
      cellRenderer: ({ data: { name, description } }) => GenericUtils
        .getCellRenderer({ name, description }),
      sortable: true,
    },
    { field: 'key', tooltipField: 'key', headerName: 'Key' },
    { field: 'access_level', tooltipField: 'access_level', headerName: 'Access Level' },
    { field: 'patient_access', tooltipField: 'patient_access', headerName: 'Patient Access' },
    { field: 'active_users_count', tooltipField: 'active_users_count', headerName: 'Active Users' },
    {
      field: 'active',
      tooltipField: 'active',
      headerName: 'Status',
      cellRenderer: (params) => (params.value ? 'Active' : 'Inactive'),
      cellStyle: (params) => ({ ...GenericUtils.statusCellStyle(params), textAlign: 'left' }),
    },
  ]);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };
  };

  const checkUnsavedChanges = (onConfirm) => {
    if (formDirty) {
      toast(
        <ConfirmToast
          config={GenericUtils.discardChangesToastConfig}
          confirm={onConfirm}
        />,
        GenericUtils.confirmToastOptions,
      );
    } else {
      onConfirm();
    }
  };

  const blocker = useCallback((tx) => {
    checkUnsavedChanges(() => {
      tx.retry();
      setPanel(null);
      setFormDirty(false);
    });
  }, [formDirty]);

  useBlocker(blocker, formDirty);

  const onRowClicked = useCallback(async (params, users) => {
    checkUnsavedChanges(async () => {
      const { key } = params.api.getSelectedRows()[0];
      const organization = await OrganizationService.getOrganization(clientId, key);

      setPanel({
        key: organization.key,
        title: organization.name,
        subtitle: organization.key,
        disabled: ['internal', 'hub-to-hub'].includes(organization.access_level),
        questions: await OrganizationService.getOrganizationsForm(organization, users),
        data: {
          ...organization,
          users: organization.users || [],
        },
      });
      setFormDirty(false);
    });
  }, [formDirty]);

  const createOrganization = async () => {
    setPanel({
      key: 'new-organization',
      title: 'Add Organization',
      questions: await OrganizationService.getOrganizationsForm({}, clientUsers),
      data: {
        status: 'Active',
        users: [],
      },
      submitText: 'Add Organization',
    });
  };

  const closeSidePanel = () => {
    checkUnsavedChanges(() => {
      setPanel(null);
      setFormDirty(false);
    });
  };

  const fetchGridData = async () => {
    const res = await OrganizationService.getAll(clientId);
    setOrgs(res);

    const users = await UsersService.getUsers(clientId);
    setClientUsers(
      users.map((u) => ({
        key: u.id.toString(),
        username: u.username,
        label: `${u.first_name} ${u.last_name} (${u.username})`,
        name: `${u.first_name} ${u.last_name}`,
      })),
    );

    setGridData(res);
  };

  useEffect(() => {
    fetchGridData();
  }, []);

  // This function compares the data values with the
  // question options to determine if any values are disabled.
  // If it finds any disabled values, it resets them to an empty string.
  const resetDisabledValues = (data, questions) => (
    Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        const question = questions.find((q) => q.field === key);

        // If the data field does not have a list of options,
        // then we don't need to check for disabled values
        if (!question?.options) {
          return [key, value];
        }

        // Get the list of disabled values for the current field
        const disabledValues = question.options
          .filter((opt) => opt.isDisabled === true)
          .map((opt) => opt.value);

        // If the current value is a disabled value, reset it to an empty string
        return [key, disabledValues.includes(value) ? null : value];
      }),
    )
  );

  const onFormChange = async ({ isDirty, data }) => {
    setFormDirty(isDirty);

    const newQuestions = await OrganizationService.getOrganizationsForm(data, clientUsers);

    setPanel((prevPanel) => ({
      ...prevPanel,
      data: data.access_level !== 'primary' ? resetDisabledValues(data, newQuestions) : data,
      questions: newQuestions,
    }));
  };

  const onFormSubmit = async (params) => {
    try {
      const { data } = params;
      setLoading(true);
      await OrganizationService.upsertOrganization(clientId, data.key, data);
      toast.success('Changes Saved!');
      setFormDirty(false);
      fetchGridData();
    } catch (error) {
      toast.error(error);
      setFormDirty(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setGridData(searchText.length > 0 ? GenericUtils.search(orgs, searchText) : orgs);
  }, [searchText]);

  return (
    <div className="container-padding-40">
      <div className="row">
        <div className="col-lg-3 col-6">
          <h2>Organizations</h2>
          <Subheader description={[`${gridData ? gridData.length.toLocaleString() : 0} items`]} />
        </div>
        <div className="col-md-6">
          <SearchBar placeholder="Search Organizations..." onChange={(e) => setSearchText(e.target.value)} />
        </div>
        <div className="col-md-3 text-align-right">
          <Button variant="link" onClick={createOrganization}>
            Create Organization
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine ag-container">
        <AgGridReact
          rowData={gridData}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: true,
            resizable: true,
          }}
          tooltipShowDelay={1500}
          rowSelection="single"
          suppressContextMenu="true"
          rowHeight="65"
          suppressCellFocus="true"
          suppressScrollOnNewData="true"
          onRowClicked={(params) => onRowClicked(params, clientUsers)}
          onGridReady={onGridReady}
        />
      </div>

      {
        panel && (
          <SidePanel
            className="side-panel"
            close={closeSidePanel}
            confirmClosePrompt={formDirty}
            loading={loading}
            style={{ width: '60%' }}
          >
            <SidePanel.Header
              title={panel.title}
              subtitle={panel.subtitle}
            />
            <SidePanel.Content className="no-tabs">
              <JulotaFormNew
                questions={panel.questions}
                data={panel.data}
                isDirty={formDirty}
                isDisabled={panel.disabled}
                submitText={panel.submitText}
                onFormChange={onFormChange}
                onFormSubmit={onFormSubmit}
              />
            </SidePanel.Content>
          </SidePanel>
        )
      }
    </div>
  );
}
